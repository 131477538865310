import {WithChildren, WithClassName} from "types/props";
import {AppSpacer} from "common/spacer/Spacer";
import {AppInfoText} from "common/text/InfoText";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

export type AppFormDescriptionProps = WithChildren & WithClassName & {
  containerClassName?: string;
  description: string;
  required?: boolean;
};

export const AppFormDescription = (props: AppFormDescriptionProps) => {
  const containerClassName = getClassNames(styles.container, props.containerClassName);
  const className = getClassNames(styles.description, props.required ? styles.required : "", props.className);

  return (
    <AppSpacer direction="vertical" unit="0" className={containerClassName}>
      <AppInfoText className={className}>
        {props.description}
      </AppInfoText>
      {props.children}
    </AppSpacer>
  );
};