import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {UdcLbcRequestsState} from "app/reducers/udc-lbc-requests/types";
import {udcLbcRequestsActions} from "app/reducers/udc-lbc-requests";
import {ProfileState} from "app/reducers/profile/types";
import {getOffsetLimit} from "common/table/utils/get-offset-limit";
import {getUdcLbcRequestsList, UdcLbcRequestsListParams} from "rest/udc-lbc-requests/get-all";
import {UDCLBCOrderDTO} from "types/entities/UDCLBCOrderDTO";

type StateSlice = {
  udcLbcRequestsState: UdcLbcRequestsState;
  profileState: ProfileState;
}

export const fetchUdcLbcRequestsList = createAsyncThunk<UDCLBCOrderDTO[], void, {state: StateSlice}>(
  "udcLbcRequests/fetchAll",
  async (_, {getState, dispatch}) => {
    try {
      const {filters: {onlyPersonal}, tableState: {pagination}} = getState().udcLbcRequestsState;
      const {profile} = getState().profileState;
      const params: UdcLbcRequestsListParams = {
        userId: profile?.id || null,
        onlyPersonal,
        ...getOffsetLimit(pagination),
      };
      return await getUdcLbcRequestsList(params);
    } catch (e: unknown) {
      if (!axios.isCancel(e)) {
        dispatch(udcLbcRequestsActions.rejectedByError());
      }
      throw new Error(e as string);
    }
  }
);