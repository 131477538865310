import {MenuItemProps, Menu} from "antd";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";
import "./styles.scss";

export const AppMenuItem = (props: MenuItemProps) => {
  const className = getClassNames(styles.item, props.className);

  return (
    <Menu.Item {...props} className={className}/>
  );
};