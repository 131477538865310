import axios, {CancelTokenSource} from "axios";
import {appApi} from "api-config";
import {SearchResponseV2} from "app/reducers/search/types";
import {TSearchParameters} from "types/search-params";
import {errorHandler} from "utils/error-handler";

export const DEFAULT_LIMIT = 10;

export type SearchContentParams = Omit<
  TSearchParameters,
  "limit" | "offset"
> & {
  limit?: number | null;
  offset?: number | null;
};

const _searchContent = () => {
  let source: CancelTokenSource | undefined;

  return (
    searchParameters: SearchContentParams,
    userId?: string
  ): Promise<SearchResponseV2> => {
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();

    const requestBody = {
      ...searchParameters,
      portalContentTypeId: searchParameters.contentTypeId,
      filters: undefined,
      searchBy: undefined,
      contentTypeId: undefined,
      searchString: searchParameters.searchString ?? null,
    };

    return appApi
      .post("/portal/search_content", requestBody, {
        params: {
          userId,
        },
      })
      .then((res) => res.data)
      .catch(errorHandler("При обращении к серверу произошла ошибка."));
  };
};

export const searchContent = _searchContent();
