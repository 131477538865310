import {PurchasesContent} from "app/components/purchases/PurchasesContent";
import {usePurchasesPage} from "app/pages/purchases/hooks/usePurchasesPage";
import {AppLayout} from "common/layout/Layout";
import {AppLoader} from "common/loader/Loader";
import {AppSpacer} from "common/spacer/Spacer";

export const PurchasesPage = () => {
  const {state} = usePurchasesPage();

  return (
    <AppLayout>
      {state.loading ? (
        <AppSpacer alignItems="center" justifyContent="center">
          <AppLoader/>
        </AppSpacer>
      ) : (
        <PurchasesContent items={state.items}/>
      )}
    </AppLayout>
  );
};
