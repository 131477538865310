import {AppTableBasePagination} from "../types/onChange";

type OffsetLimit = {
  offset: number;
  limit: number;
}

export const getOffsetLimit = (pagination: AppTableBasePagination): OffsetLimit => {
  if (!pagination.current || !pagination.pageSize) {
    return {
      offset: 0,
      limit: 0,
    };
  }

  return {
    offset: (pagination.current - 1) * pagination.pageSize,
    limit: pagination.pageSize,
  };
};