import {useState} from "react";

export type ModalService = {
  state: {
    visible: boolean;
  },
  methods: {
    show(): void;
    hide(): void;
  }
};

export const useModalService = (): ModalService => {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return {
    state: {
      visible,
    },
    methods: {
      show,
      hide,
    },
  };
};