import {AlertProps} from "antd/lib/alert";
import {useAppNavigation} from "app/routing/use-navigation/use-navigation";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect, useState} from "react";
import {updatePaymentStatus} from "rest/purchase/updatePurchaseStatus";

export const usePaymentPageContent = (success: boolean) => {
  const [disabled, setDisabled] = useState(true);
  const {toPurchases} = useAppNavigation();

  const {profileState} = useAppSelector((state) => state);

  useEffect(() => {
    if (profileState.profile === null) {
      return;
    }
    updatePaymentStatus(success, profileState.profile?.id ?? "").then(() =>
      setDisabled(false)
    );
  }, []);

  const onClick = () => {
    toPurchases();
  };

  const message = success
    ? "Платеж прошел успешно"
    : "При оплате заказа произошла ошибка";

  const type = success ? "info" : ("error" as AlertProps["type"]);

  return {
    message,
    type,
    disabled,
    onClick,
  };
};
