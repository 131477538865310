import {BlockProps} from "antd/lib/typography/Base";
import {WithChildren} from "types/props";
import {AppText} from "common/text/Text";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

type AppCardDescriptionProps = BlockProps & WithChildren & {
  title: string;
  titleStretch?: boolean;
  containerClassName?: string;
};

export const AppCardDescription = (props: AppCardDescriptionProps) => {
  const {title, titleStretch = false, containerClassName, className, children, ...paragraphProps} = props;

  const containerStyle = getClassNames(styles.container, containerClassName);
  const titleStyle = getClassNames(styles.title, titleStretch ? styles["title--stretch"] : "");
  const contentStyle = getClassNames(styles.content, className);

  return children ? (
    <div className={containerStyle}>
      <AppText className={titleStyle}>
        {title}
      </AppText>
      <AppText className={contentStyle} {...paragraphProps}>
        {children}
      </AppText>
    </div>
  ) : null;
};
