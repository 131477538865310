import {startDateValidator} from "./validation/startDate";
import {endDateValidator} from "./validation/endDate";
import {FormInstance} from "antd";
import {ResourceElement} from "app/reducers/search/types";
import {reserveFormFields} from "./fields";
import {AppForm} from "common/form/Form";
import {AppFormItem} from "common/form/item/Base";
import {AppDatePicker} from "common/date-picker/DatePicker";
import styles from "./styles.module.scss";

export type ReserveModalProps = {
  loading: boolean;
  form: FormInstance;
  element: ResourceElement;
}

export const ReserveForm = (props: ReserveModalProps) => {
  return (
    <AppForm form={props.form} className={styles.form}>
      <AppFormItem name={reserveFormFields.reserveStarts}
                   label="Зарезервировать"
                   className={styles.control}
                   dependencies={[reserveFormFields.reserveEnds]}
                   rules={[{
                      validator: startDateValidator(props.form, props.element),
                   }]}>
        <AppDatePicker disabled={props.loading}/>
      </AppFormItem>
      <div className={styles.dash}>&mdash;</div>
      <AppFormItem name={reserveFormFields.reserveEnds}
                   className={styles.control}
                   dependencies={[reserveFormFields.reserveStarts]}
                   rules={[{
                     validator: endDateValidator(props.form, props.element),
                   }]}>
        <AppDatePicker disabled={props.loading}/>
      </AppFormItem>
    </AppForm>
  );
};