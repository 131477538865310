import {ContentCard} from "app/components/search/content-card/ContentCard";
import {PurchaseItem} from "app/reducers/purchases/types";
import {AppEmpty} from "common/empty/Empty";
import {AppSpacer} from "common/spacer/Spacer";
import {AppTitle} from "common/title/Title";
import {modifyResourceVersion} from "utils/resource/modifyResourceVersion";

import styles from "./styles.module.scss";

type PurchasesContentProps = {
  items: PurchaseItem[];
};

export const PurchasesContent = (props: PurchasesContentProps) => {
  if (!props.items.length) {
    return (
      <AppSpacer unit="50" className={styles.container}>
        <AppEmpty description="У вас пока нет покупок"/>
      </AppSpacer>
    );
  }

  return (
    <AppSpacer unit="50" className={styles.container}>
      <AppTitle level={1} className={styles.title}>
        Мои товары
      </AppTitle>
      <AppSpacer>
        {props.items.map((purchase) => {
          const element = modifyResourceVersion(purchase.content);

          return (
            <ContentCard
              key={purchase.id}
              element={element}
              quantity={purchase.quantity}
              purchasePrice={purchase.price}
              isPurchaseItem
            />
          );
        })}
      </AppSpacer>
    </AppSpacer>
  );
};
