import {SamgmuLogoIcon} from "common/icons/SamgmuLogo";
import {AppTitle} from "common/title/Title";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

type AppLinkCardProps = {
  title: string;
  href?: string;
  className?: string;
}

export const AppAnchorLinkCard = (props: AppLinkCardProps) => {
  return (
    <a href={props.href}
       className={getClassNames(styles.card, props.className)}
       target="_blank"
       rel="noreferrer">
      <AppTitle level={4} className={styles.title}>
        {props.title}
      </AppTitle>
      <SamgmuLogoIcon className={styles.logo}/>
    </a>
  );
};