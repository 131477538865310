import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getPurchases} from "app/actions/purchases/purchases";
import {PurchaseItem, PurchasesState} from "app/reducers/purchases/types";

const initialState: PurchasesState = {
  purchaseItems: [],
  loading: true,
  error: "",
};

const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    [getPurchases.pending.type]: () => initialState,
    [getPurchases.rejected.type]: (state: PurchasesState) => {
      state.loading = false;
      state.error = "При загрузке списка покупок произошла ошибка";
    },
    [getPurchases.fulfilled.type]: (
      state: PurchasesState,
      action: PayloadAction<PurchaseItem[]>
    ) => {
      state.purchaseItems = action.payload;
      state.loading = false;
      state.error = "";
    },
  },
});

export const {
  reducer: purchasesReducer,
  actions: purchasesActions,
} = purchasesSlice;
