import {AppOutlineButton} from "common/button/outline/Outline";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {useNavigate} from "react-router";
import styles from "./styles.module.scss";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <AppSpacer alignItems="center" justifyContent="center"
               className={styles.container}>
      <AppText className={styles.error}>404</AppText>
      <AppText className={styles.message}>Страница не найдена</AppText>
      <AppOutlineButton className={styles.link} onClick={() => navigate("/", {replace: true})}>
        Вернуться на главную
      </AppOutlineButton>
    </AppSpacer>
  );
};