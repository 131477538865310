import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {StrictMode, Suspense} from "react";
import {ConfigProvider} from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import reportWebVitals from "./reportWebVitals";
import {store} from "app/store";
import "antd/dist/antd.min.css";
import "./index.scss";
import {App} from "app/components/app/App";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {AppLoader} from "common/loader/Loader";
import {keycloak} from "keycloak/keycloak";
import {ignoreAntdErrorInReactStrictMode} from "utils/ignore-antd-strict-mode-error";

ignoreAntdErrorInReactStrictMode();

ReactDOM.render(
  <StrictMode>
    <ReactKeycloakProvider LoadingComponent={<AppLoader/>}
                           initOptions={{onLoad: "check-sso"}}
                           authClient={keycloak}>
      <Provider store={store}>
        <ConfigProvider locale={ruRU}>
          <BrowserRouter>
            <Suspense fallback={<AppLoader/>}>
              <App/>
            </Suspense>
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    </ReactKeycloakProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
