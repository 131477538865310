import {Alert} from "antd";
import {usePaymentPageContent} from "./hooks/usePaymentPageContent";
import {AppPrimaryButton} from "common/button/primary/Primary";

import styles from "./styles.module.scss";

type PaymentPageProps = {
  success: boolean;
};

export const PaymentPageContent = (props: PaymentPageProps) => {
  const {message, type, disabled, onClick} = usePaymentPageContent(
    props.success
  );

  return (
    <Alert
      className={styles.alert}
      message={message}
      type={type}
      showIcon
      action={
        <AppPrimaryButton disabled={disabled} onClick={onClick}>
          К покупкам
        </AppPrimaryButton>
      }
    />
  );
};
