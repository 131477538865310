import {WithChildren} from "types/props";
import {AppText} from "common/text/Text";
import styles from "./styles.module.scss";

type FormItemContainerProps = WithChildren & {
  title: string;
}

export const FormItemContainer = (props: FormItemContainerProps) => {
  return (
    <label className={styles.container}>
      <AppText className={styles.title}>{props.title}</AppText>
      {props.children}
    </label>
  );
};