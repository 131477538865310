import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";
import {ResourceByIdResponseV2} from "./types";

export const fetchResourceById = (
  contentId: string,
  userId?: string
): Promise<ResourceByIdResponseV2> => {
  return appApi
    .get("/portal/content", {
      params: {
        id: contentId,
        userId,
      },
    })
    .then((res) => res.data)
    .catch(errorHandler("Не удалось загрузить данные"));
};
