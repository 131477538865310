import parse from "html-react-parser";

const mapObj = {
  "&lt;": "<",
  "&gt;": ">",
};

export const parseHTML = (html: string): ReturnType<typeof parse> => {
  // todo backend response should contain valid HTML markup
  const reg = new RegExp(Object.keys(mapObj).join("|"),"g");
  const replaced = html.replace(reg, matched => mapObj[matched as keyof typeof mapObj]);

  return parse(replaced);
};



