import {Card, CardProps} from "antd";
import {getClassNames} from "utils/get-class-names/get-class-names";
import "./styles.scss";

type AppCardProps = CardProps & {
  className?: string;
}

export const AppCard = (props: AppCardProps) => {
  const className = getClassNames("app-card", props.className);
  return props.children ? (
    <Card {...props} className={className}>
      {props.children}
    </Card>
  ) : null;
};