import React from "react";
import styles from "./styles.module.scss";

export type AppInputPrefixProps = {
  prefix?: React.ReactNode;
};

export const AppInputPrefix = (props: AppInputPrefixProps) => {
  return props.prefix ? (
    <div className={styles.prefix}>
      {props.prefix}
    </div>
  ) : null;
};
