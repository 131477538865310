import {useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {searchContentActions} from "app/reducers/search";
import {getURLSearchParameters} from "utils/search/helpers";
import {getHighlightParts} from "../../utils";

export const useContentByTypeService = () => {
  const location = useLocation();
  const {loading, elements} = useAppSelector(
    (state) => state.searchContentState
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(searchContentActions.clear());
    };
  }, []);

  const searchParameters = useMemo(() => getURLSearchParameters(), [location]);
  const shouldRenderSimilarContentPanel =
    searchParameters.contentId && searchParameters.contentTypeId;

  const getHighlight = () => {
    const {searchString, searchBy} = getURLSearchParameters();

    return {
      highlightParts: getHighlightParts(searchBy),
      pattern: searchString,
    };
  };

  return {
    loading,
    elements,
    searchParameters,
    shouldRenderSimilarContentPanel,
    highlight: getHighlight(),
  };
};
