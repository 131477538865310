import {appApi} from "api-config";
import {ProfileRequestBody} from "app/reducers/profile/types";
import {CubaUser} from "types/entities/CubaUser";
import {errorHandler} from "utils/error-handler";

export const findOrRegisterUser = (userData: ProfileRequestBody): Promise<CubaUser> => {
  return appApi
    .post("v2/services/educationalportal_KeycloakRegistrationService/findOrRegisterUser", {
      userData,
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось загрузить данные пользователя"));
};