import {BasketContent} from "app/components/basket/BasketContent";
import {TotalPrice} from "app/components/basket/total-price/TotalPrice";
import {useBasketPage} from "app/pages/basket/hook/useBasketPage";
import {AppEmpty} from "common/empty/Empty";
import {AppLayout} from "common/layout/Layout";
import {AppLoader} from "common/loader/Loader";
import {AppSpacer} from "common/spacer/Spacer";

export const BasketPage = () => {
  const {state, methods} = useBasketPage();

  return (
    <AppLayout>
      {state.items.length ? (
        <AppSpacer
          unit="20"
          direction="horizontal"
          justifyContent="space-between"
        >
          <BasketContent
            items={state.items}
            selectedItems={state.selectedItemsIds}
            onClickCheckbox={methods.onClickCheckbox}
            onClickSelectAll={methods.onClickCheckAll}
            allSelected={state.allSelected}
          />
          <TotalPrice total={state.total} onBuyClick={methods.onBuyClick}/>
        </AppSpacer>
      ) : (
        <AppEmpty description="Корзина пока пуста"/>
      )}
      {state.loading && (
        <AppSpacer alignItems="center" justifyContent="center">
          <AppLoader/>
        </AppSpacer>
      )}
    </AppLayout>
  );
};
