import React from "react";
import {FilterDto} from "../../types";
import {FilterInputItem} from "../form-items/input-item/InputItem";
import {FilterSelectItem} from "../form-items/select-item/SelectItem";
import {FilterCheckboxItem} from "../form-items/checkbox-item/CheckboxItem";
import {FilterYearItem} from "../form-items/year-item/YearItem";
import {EFilterType} from "types/enums";
import {FilterDateItem} from "../form-items/date-item/DateItem";

type FiltersProps = {
  filters: FilterDto[];
}

export const Filters = (props: FiltersProps) => {

  const renderFilter = (filter: FilterDto) => {
    if (filter.fullPathToProperty === "publishDate") {
      return <FilterYearItem fieldName={filter.id} title={filter.name}/>;
    }
    if (filter.elements) {
      return <FilterSelectItem fieldName={filter.id} placeholder={filter.name} options={filter.elements}/>;
    }
    if (filter.type === EFilterType.BOOLEAN) {
      return <FilterCheckboxItem fieldName={filter.id} title={filter.name}/>;
    }
    if (filter.type === EFilterType.DATE) {
      return <FilterDateItem fieldName={filter.id} title={filter.name}/>;
    }
    return <FilterInputItem fieldName={filter.id} title={filter.name}/>;
  };

  const getFilters = () => {
    return props.filters.map(filter => {
      return (
        <React.Fragment key={filter.id}>
          {renderFilter(filter)}
        </React.Fragment>
      );
    });
  };

  return (
    <>{getFilters()}</>
  );
};