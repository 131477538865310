import {AppLayout} from "common/layout/Layout";
import {AppSpacer} from "common/spacer/Spacer";
import {HeaderSearchPanel} from "app/components/common/header-search-panel/HeaderSearchPanel";
import {HomeContent as _HomeContent} from "app/components/home/home-content/HomeContent";
import {withLoading} from "app/hocs/with-loading/with-loading";
import {useHomePage} from "./hook/use-home-page";
import {AppContentTypesList} from "app/components/search/content-types-list/ContentTypesList";

const HomeContent = withLoading(_HomeContent);

export const HomePage = () => {
  const {loading, newContent, popularContent, searchState} = useHomePage();

  return (
    <AppLayout>
      <HeaderSearchPanel/>
      <AppSpacer
        unit="20"
        direction="horizontal"
        justifyContent="space-between"
      >
        <HomeContent
          loading={loading}
          newContent={newContent}
          popularContent={popularContent}
          searchState={searchState}
        />
        <AppContentTypesList/>
      </AppSpacer>
    </AppLayout>
  );
};
