import {Rate, RateProps} from "antd";
import {getClassNames} from "utils/get-class-names/get-class-names";

import styles from "./styles.module.scss";

type AppRateProps = RateProps;

export const AppRate = (props: AppRateProps) => {
  const className = getClassNames(styles.rate, props.className);
  return <Rate {...props} className={className}/>;
};
