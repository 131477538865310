import {appApi} from "api-config";
import {GetBasketContentResponse} from "rest/basket/types";
import {errorHandler} from "utils/error-handler";

export const getBasketContent = (
  userId: string
): Promise<GetBasketContentResponse> => {
  return appApi
    .get(`/portal/basket_items?userId=${userId}`)
    .then((res) => res.data)
    .catch(errorHandler("Не удалось загрузить содержимое корзины"));
};
