import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getAllContentTypes} from "app/actions/search/content-types";
import {ContentTypesState, ContentType} from "./types";

const initialState: ContentTypesState = {
  contentTypes: [],
};

const contentTypesSlice = createSlice({
  name: "contentTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllContentTypes.fulfilled.type]: (state: ContentTypesState, action: PayloadAction<ContentType[]>) => {
      state.contentTypes = action.payload;
    },
  }
});

export const contentTypesReducer = contentTypesSlice.reducer;

