import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ProfileState} from "app/reducers/profile/types";
import {getOffsetLimit} from "common/table/utils/get-offset-limit";
import {StatementRequestsState} from "app/reducers/statement-request/types";
import {getStatementRequestsList, StatementRequestsListParams} from "rest/statement-requests/get-all";
import {StatementRequestDTO} from "types/entities/StatementRequestDTO";
import {statementRequestsActions} from "app/reducers/statement-request";

type StateSlice = {
  statementRequestsState: StatementRequestsState;
  profileState: ProfileState;
}

export const fetchStatementRequestsList = createAsyncThunk<StatementRequestDTO[], void, {state: StateSlice}>(
  "statementRequests/fetchAll",
  async (_, {getState, dispatch}) => {
    try {
      const {filters: {onlyPersonal}, tableState: {pagination}} = getState().statementRequestsState;
      const {profile} = getState().profileState;
      const params: StatementRequestsListParams = {
        id: profile?.id || null,
        onlyPersonal,
        ...getOffsetLimit(pagination),
      };
      const data: StatementRequestDTO[] = await getStatementRequestsList(params);
      return data;
    } catch (e: unknown) {
      if (!axios.isCancel(e)) {
        dispatch(statementRequestsActions.rejectedByError());
      }
      throw new Error(e as string);
    }
  }
);