import {createAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "app/store/types";
import {InquiryOrderDTO} from "types/entities/InquiryOrderDTO";

export type VirtualReferenceAppendedResult = {
  elements: InquiryOrderDTO[];
  total: number;
}

export const virtualReferenceAppended = createAction<VirtualReferenceAppendedResult>("virtualReference/append");

export const appendVirtualReference = (virtualReference: InquiryOrderDTO) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {profile} = getState().profileState;
    const {
      filters: {onlyPersonal},
      elements,
      tableState: {pagination: {total = 0}}
    } = getState().virtualReferenceState;

    if (profile?.id && !virtualReference.isPublic && !onlyPersonal) {
      return;
    }

    dispatch(virtualReferenceAppended({
      elements: [virtualReference, ...elements],
      total: total + 1,
    }));
  };
};