import Empty, {EmptyProps} from "antd/lib/empty";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

export const AppEmpty = (props: EmptyProps) => {
  const className = getClassNames(styles.empty, props.className);

  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
           description="Нет данных"
           className={className} {...props}/>
  );
};