import {Routes, Route} from "react-router-dom";
import {availableRoutes} from "app/routing/available-root-routes";
import {AppHeader} from "app/components/header/Header";
import {useAuth} from "app/hooks/auth";
import {AppLoader} from "common/loader/Loader";
import styles from "./styles.module.scss";
import {CookiesInfo} from "../cookie/CookiesInfo";

export const App = () => {
  const service = useAuth();

  if (service.loading) {
    return <AppLoader/>;
  }

  return (
    <div className={styles.wrapper}>
      <AppHeader/>
      <Routes>
        {
          availableRoutes.map((props, index) => {
            return (
              <Route key={index} {...props}/>
            );
          })
        }
      </Routes>
      <CookiesInfo/>
    </div>
  );
};
