import {appApi} from "api-config";
import {PurchaseItem} from "app/reducers/purchases/types";
import {errorHandler} from "utils/error-handler";

export const getUserPurchases = (userId: string): Promise<PurchaseItem[]> => {
  return appApi
    .get("/portal/purchases_by_user", {
      params: {
        userId,
      },
    })
    .then((res) => res.data)
    .catch(errorHandler("Не удалось загрузить список покупок"));
};
