import {SvgProps} from "types/props";

export const CalendarIcon = (props: SvgProps) => {
  const {fill = "#333333", ...rest} = props;

  return (
    <svg {...rest} width="1.6rem" height="1.6rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M5 5C4.44772 5 4 4.55228 4 4H3C2.44772 4 2 4.44772 2 5V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V5C14 4.44772 13.5523 4 13 4H12C12 4.55228 11.5523 5 11 5C10.4477 5 10 4.55228 10 4H6C6 4.55228 5.55228 5 5 5ZM10 2H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V2H3C1.34315 2 0 3.34315 0 5V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V5C16 3.34315 14.6569 2 13 2H12V1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1V2ZM5 7C4.44772 7 4 7.44772 4 8C4 8.55229 4.44772 9 5 9H11C11.5523 9 12 8.55229 12 8C12 7.44772 11.5523 7 11 7H5Z"/>
    </svg>
  );
};