import {appApi} from "api-config";
import {ContentType} from "app/reducers/content-types/types";
import {errorHandler} from "utils/error-handler";

export const fetchAllContentTypes = (): Promise<ContentType[]> => {
  return appApi
    .get("/portal/content_types")
    .then((res) => res.data)
    .catch(errorHandler("Не удалось загрузить типы ресурсов"));
};
// export const fetchAllContentTypes = (): Promise<ContentType[]> => {
//   return appApi
//     .get("v2/services/educationalportal_RestSearchService/getAllContentTypes")
//     .then((res) => res.data)
//     .catch(errorHandler("Не удалось загрузить типы ресурсов"));
// };
