import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {findContent} from "app/actions/search/content";
import {SearchState, SearchResponse} from "./types";

const initialState: SearchState = {
  loading: true,
  elements: [],
  contentTypeToCounts: [],
  searchLogId: "",
};

const searchContentSlice = createSlice({
  name: "SEARCH_CONTENT",
  initialState,
  reducers: {
    rejectedByError: (state: SearchState) => {
      state.loading = false;
      state.elements = [];
      state.contentTypeToCounts = [];
    },
    clear: (state: SearchState) => {
      state.loading = true;
      state.elements = [];
      state.contentTypeToCounts = [];
    },
  },
  extraReducers: {
    [findContent.pending.type]: (state: SearchState) => {
      state.loading = true;
    },
    [findContent.fulfilled.type]: (
      state: SearchState,
      action: PayloadAction<SearchResponse>
    ) => {
      state.loading = false;
      state.elements = action.payload.elements;
      state.searchLogId = action.payload.searchLogId;
      state.contentTypeToCounts = action.payload.contentTypeToCounts;
    },
  },
});

export const {
  reducer: searchContentReducer,
  actions: searchContentActions,
} = searchContentSlice;
