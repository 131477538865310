import {LinkType} from "app/reducers/search/types";
import {useLinkButton} from "./hooks/useLinkButton";
import {AppOutlineButton} from "common/button/outline/Outline";
import React from "react";

type LinkButtonProps = {
  link: string;
  contentId: string;
  linkType?: LinkType;
};

export const LinkButton = (props: LinkButtonProps) => {
  const {loading, onClick, buttonText} = useLinkButton(
    props.link,
    props.contentId,
    props.linkType
  );

  return (
    <AppOutlineButton loading={loading} onClick={onClick}>
      {buttonText}
    </AppOutlineButton>
  );
};
