import {getFlexStyles} from "./utils/get-flex-styles";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {AppSpacerMarginUnit, AppSpacerProps, SpacerDirection} from "./types";
import styles from "./styles.module.scss";

export const AppSpacer = (props: AppSpacerProps) => {
  const {
    direction = "vertical",
    unit = "16",
    alignItems,
    justifyContent,
    ...rest
  } = props;

  const style = getFlexStyles(alignItems, justifyContent);
  const className = getSpacerClassName(direction, unit, rest.className);

  return (
    <div {...rest} className={className} style={style}>
      {props.children}
    </div>
  );
};

const getSpacerClassName = (
  direction: SpacerDirection,
  unit: AppSpacerMarginUnit,
  className?: string,
) => {
  const base = direction === "horizontal" ?
    styles[`spacer-${unit}`] :
    styles[`spacer--vertical-${unit}`];

  return getClassNames(base, className);
};