import {ResourceElementV2} from "app/reducers/search/types";

export type BasketItem = {
  amount: number;
  content: ResourceElementV2;
  id: string;
  price: number;
  selected: boolean;
  userId: string;
  status: BasketItemStatus;
};

export type BasketState = {
  basketItems: BasketItem[];
  loading: boolean;
  error: string;
};

export enum BasketItemStatus {
  ACTIVE = "active",
  PURCHASED = "purchased",
  DELETED = "deleted",
}
