import {useEffect} from "react";
import {useParams} from "react-router";
import {getResourceById} from "app/actions/resource/resource";
import {useScrollToTop} from "app/hooks/scroll-to-top";
import {ResourcePageParamsKeys} from "app/routing/resource/types";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useAppSelector} from "app/store/hooks/use-selector";
import {currentElementActions} from "app/reducers/resource";
import {getSearchPageLink} from "utils/search/helpers";
import {useModalService} from "app/hooks/modal/modal-service";

export const useResourcePage = () => {
  const dispatch = useAppDispatch();
  const {content, similarElements, loading, error} = useAppSelector(
    (state) => state.currentElementState
  );
  const {profileState} = useAppSelector((state) => state);
  const modal = useModalService();
  const {id} = useParams<ResourcePageParamsKeys>();

  useEffect(() => {
    if (id) {
      const action = getResourceById({
        contentId: id,
        userId: profileState.profile?.id,
      });
      dispatch(action);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(currentElementActions.clear());
    };
  }, []);

  useScrollToTop([id]);

  const getLink = () => {
    return getSearchPageLink({
      contentId: id,
      contentTypeId: content.contentType?.id ?? null,
    });
  };

  return {
    state: {
      content,
      similarElements,
      loading,
      error,
      link: getLink(),
      visible: modal.state.visible,
      profile: profileState.profile,
    },
    methods: {
      ...modal.methods,
    },
  };
};
