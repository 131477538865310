import {useState} from "react";
import {AppSelectProps} from "../../types";

export const useSelectState = <T>(props: AppSelectProps<T>, initialState: T) => {
  const [value, setValue] = useState<T>(initialState);

  const getValue = () => {
    return props.value ? props.value : value;
  };

  const onChange = (_value: T) => {
    if (!props.value) {
      setValue(_value);
    }
  };

  const onClear = () => {
    if (props.onClear) {
      props.onClear();
    }

    setValue(initialState);
  };

  return {
    value: getValue(),
    onChange,
    onClear,
  };
};