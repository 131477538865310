import {ResourceElement} from "app/reducers/search/types";
import {AppCardDescription} from "common/descriptions/card-description/CardDescription";
import {AppInfoText} from "common/text/InfoText";
import {AppText} from "common/text/Text";
import {USER_DATE_WITHOUT_YEAR} from "utils/date/date";
import {formatDate} from "utils/date/format-date/format-date";

export type ReserveDescriptionProps = {
  element: ResourceElement;
}

export const ReserveDescription = (props: ReserveDescriptionProps) => {
  const {availableForReserve, forReserve, availableReserveStarts, availableReserveEnds, canBeAvailable} = props.element;

  return availableForReserve ? (
    <AppCardDescription title="Статус:">
      {
        forReserve ? (
          <>
            <AppText type="success">
              Доступно для резервирования - {forReserve}шт.
            </AppText>
            <AppText type="success">
              {availableReserveStarts && `C ${formatDate(availableReserveStarts, USER_DATE_WITHOUT_YEAR)}`}
              {availableReserveEnds && ` по ${formatDate(availableReserveEnds, USER_DATE_WITHOUT_YEAR)}`}
            </AppText>
          </>
        ) : (
          <>
            <AppInfoText>Недоступно.</AppInfoText>
            {
              canBeAvailable && (
                <AppInfoText>
                  Станет доступно для резервировании: {formatDate(canBeAvailable, USER_DATE_WITHOUT_YEAR)}.
                </AppInfoText>
              )
            }
          </>
        )
      }
    </AppCardDescription>
  ) : null;
};