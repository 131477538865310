import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";
import {BypassSheetServerResponse} from "./types";

export const getBypassSheetsForUser = (userId: string): Promise<BypassSheetServerResponse> => {
  return appApi
    .post("v2/services/educationalportal_BypassSheetService/getBypassSheetsForUser", {
      searchParameters: {
        userId,
      }
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось загрузить запросы"));
};