import {Comment} from "app/reducers/comments/types";
import {useState} from "react";
import {AppCollapse} from "common/collapse/Collapse";
import {AppCollapsePanel} from "common/collapse/CollapsePanel";
import {AppPrimaryButton} from "common/button/primary/Primary";
import {PanelHeader} from "./panel-header/PanelHeader";
import {CommentCard} from "./comment-card/CommentCard";

type CommentsPanelProps = {
  comments: Comment[];
  openModal: () => void;
  hideAddComment: boolean;
};

export const CommentsPanel = (props: CommentsPanelProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {comments} = props;

  const onClickExtraButton = () => {
    props.openModal();
  };

  const panelExtra = props.hideAddComment ? null : (
    <div onClick={(e) => e.stopPropagation()}>
      <AppPrimaryButton onClick={onClickExtraButton}>
        Добавить отзыв
      </AppPrimaryButton>
    </div>
  );

  const onChange = () => {
    if (comments.length > 0) {
      setIsOpen((prev) => !prev);
    }
  };

  const collapsible = comments.length > 0 ? "header" : "disabled";

  return (
    <AppCollapse onChange={onChange} collapsible={collapsible}>
      <AppCollapsePanel
        showArrow={false}
        key={"1"}
        header={<PanelHeader isOpen={isOpen} commentsCount={comments.length}/>}
        extra={panelExtra}
      >
        {comments.map((comment) => {
          return (
            <CommentCard
              key={comment.id}
              authorName={comment.userEmail}
              rating={comment.rating}
              description={comment.comment}
            />
          );
        })}
      </AppCollapsePanel>
    </AppCollapse>
  );
};
