import {
  CountElementsByType,
  ResourceElement,
} from "app/reducers/search/types";
import {RESOURCE_PAGE_PATH} from "app/routing/resource/constants";
import {AppEmpty} from "common/empty/Empty";
import {AppLink} from "common/link/Link";
import {AppSpacer} from "common/spacer/Spacer";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {replacePathParams} from "utils/replace-path-params/replace-path-params";
import {
  getSearchPageLink,
  getURLSearchParameters,
} from "utils/search/helpers";
import {ContentMinCardListHeader} from "./list-header/ListHeader";
import {HighlightProps} from "types/props";
import {ContentCardMin} from "../content-card-min/ContentCardMin";
import styles from "./styles.module.scss";

type ContentMinListsProps = HighlightProps & {
  elements: Array<ResourceElement[]>;
  elementsCount?: CountElementsByType[];
};

export const ContentMinCardLists = (props: ContentMinListsProps) => {
  const {elements, elementsCount} = props;
  const {searchString, searchBy} = getURLSearchParameters();

  const getLink = (contentTypeId: string) => {
    return getSearchPageLink({searchString, searchBy, contentTypeId});
  };

  return (
    <AppSpacer unit="50" className={styles.content}>
      {!elements.length && <AppEmpty/>}
      {elements
        .sort(sortByOrder)
        .map((groupedElements: ResourceElement[], index: number) => {
          const count =
            elementsCount?.find(
              (type) => type.contentType === groupedElements[0].type
            )?.count ?? 0;
          const link = groupedElements[0].type
            ? getLink(groupedElements[0].type)
            : "#";

          return (
            <ContentMinCardList
              key={groupedElements[0].type || index}
              title={groupedElements[0].contentType?.pluralName || ""}
              count={count}
              data={groupedElements}
              link={link}
              highlight={props.highlight}
            />
          );
        })}
    </AppSpacer>
  );
};

type ContentMinCardListProps = HighlightProps & {
  data: ResourceElement[];
  title: string;
  count?: number;
  link: string;
  cardClassName?: string;
  linkClassName?: string;
  hideHeaderLink?: boolean;
};

export const ContentMinCardList = (props: ContentMinCardListProps) => {
  if (!props.data.length) {
    return null;
  }
  const linkClassName = getClassNames(styles.link, props.linkClassName);
  return (
    <AppSpacer unit="16" className={styles.container}>
      <ContentMinCardListHeader
        title={props.title}
        count={props.count}
        link={props.link}
        hideHeaderLink={props.hideHeaderLink}
      />
      <AppSpacer direction="horizontal">
        {props.data.map((item: ResourceElement) => {
          return (
            <AppLink
              to={replacePathParams(RESOURCE_PAGE_PATH, [item.id])}
              key={item.id}
              className={linkClassName}
            >
              <ContentCardMin
                className={props.cardClassName}
                element={item}
                highlight={props.highlight}
              />
            </AppLink>
          );
        })}
      </AppSpacer>
    </AppSpacer>
  );
};

export function sortByOrder(
  first: ResourceElement[],
  second: ResourceElement[]
) {
  return (
    Number(first[0].contentType?.order) - Number(second[0].contentType?.order)
  );
}
