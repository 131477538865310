import {appApi} from "api-config";
import {ExhibitionItem} from "app/reducers/exhibitions/types";
import {errorHandler} from "utils/error-handler";

export const getVirtualExhibitionById = (id: string): Promise<ExhibitionItem> => {
  return appApi
    .post("v2/services/educationalportal_VirtualExhibitionService/getVirtualExhibitionById", {
      id,
    })
    .then(res => res.data)
    .catch(errorHandler("При обращении к серверу произошла ошибка"));
};