import {appApi} from "api-config";
import {BasketItem} from "app/reducers/basket/types";
import {AddToBasketRequestParams} from "rest/basket/types";
import {errorHandler} from "utils/error-handler";

export const addToBasket = (
  params: AddToBasketRequestParams
): Promise<BasketItem> => {
  return appApi
    .post("/portal/basket_item", {
      ...params,
    })
    .then((res) => {
      return res.data;
    })
    .catch(errorHandler("Не удалось добавить товар в корзину"));
};
