import {MenuProps} from "antd/lib/menu";
import {AppMenuBase} from "../Menu";
import {DropdownAction} from "../types";
import {renderTextMenuItem} from "./generate-text-menu/generate-text-menu";

type AppTextMenuProps = MenuProps & {
  actions: DropdownAction[];
};

export const AppTextMenu = (props: AppTextMenuProps) => {
  const {actions, ...menuProps} = props;
  return (
    <AppMenuBase {...menuProps}>
      {
        actions.map(renderTextMenuItem)
      }
    </AppMenuBase>
  );
};