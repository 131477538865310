import {SpacerAlignDirections, SpacerJustifyDirections} from "../types";

export const getFlexStyles = (
  alignItems?: SpacerAlignDirections,
  justifyContent?: SpacerJustifyDirections,
) => {
  return {
    alignItems,
    justifyContent,
  };
};