import {createAsyncThunk} from "@reduxjs/toolkit";
import {addComment, CommentDTO} from "rest/comments/add-comment";
import {getCommentsById} from "rest/comments/get-comments-by-id";
import {CommentsResponse} from "rest/comments/types";

type RequestOptions = {
  contentId: string;
};

export const getComments = createAsyncThunk(
  "currentElementComments/getCommentById",
  async ({contentId}: RequestOptions) => {
    try {
      const response: CommentsResponse = await getCommentsById(contentId);
      return response;
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);

export const createComment = createAsyncThunk(
  "currentElementComments/addComment",
  async (payload: CommentDTO) => {
    try {
      const response: Comment = await addComment(payload);
      return response;
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);
