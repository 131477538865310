import {ResourceElement} from "app/reducers/search/types";

export const groupByType = (
  data: ResourceElement[] = []
): Array<ResourceElement[]> => {
  const groupedElements: Map<string, ResourceElement[]> = data.reduce(
    (acc: Map<string, ResourceElement[]>, item: ResourceElement) => {
      if (!item.type) {
        return acc;
      }
      if (acc.has(item.type)) {
        acc.set(item.type, [...acc.get(item.type)!, item]);
      } else {
        acc.set(item.type, [item]);
      }
      return acc;
    },
    new Map()
  );

  return Array.from(groupedElements.values());
};
