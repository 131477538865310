import {useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {useSearch} from "app/hooks/search";
import {useScrollToTop} from "app/hooks/scroll-to-top";
import {getURLSearchParameters} from "utils/search/helpers";

export const useSearchService = () => {
  const location = useLocation();
  const search = useSearch();

  const searchParams = useMemo(() => getURLSearchParameters(), [location]);

  useEffect(() => {
    search(searchParams);
  }, [searchParams]);

  useScrollToTop([searchParams]);

  return {
    isContentByType: !!searchParams.contentTypeId,
  };
};