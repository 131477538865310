import {SvgProps} from "types/props";

export const ShoppingCart = (props: SvgProps) => {
  const {stroke = "#3575C7"} = props;
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66289 19.8746C7.70533 19.8746 8.5504 19.0296 8.5504 17.9871C8.5504 16.9447 7.70533 16.0996 6.66289 16.0996C5.62045 16.0996 4.77539 16.9447 4.77539 17.9871C4.77539 19.0296 5.62045 19.8746 6.66289 19.8746Z"
        fill={stroke}
      />
      <path
        d="M17.9871 19.8746C19.0296 19.8746 19.8746 19.0296 19.8746 17.9871C19.8746 16.9447 19.0296 16.0996 17.9871 16.0996C16.9447 16.0996 16.0996 16.9447 16.0996 17.9871C16.0996 19.0296 16.9447 19.8746 17.9871 19.8746Z"
        fill={stroke}
      />
      <path
        d="M1 1H3.51667L4.27167 4.77501M4.27167 4.77501L6.03334 13.5834H18.6167L21.1334 4.77501H4.27167Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
