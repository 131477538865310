import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchUdcLbcRequestsList} from "app/actions/udc-lbc-requests/udc-lbc-requests-list";
import {
  udcLbcRequestAppended,
  UdcLbcRequestAppendedResult
} from "app/actions/udc-lbc-requests/append-udc-lbc-request";
import {DEFAULT_PAGINATION} from "common/table/utils/default-pagination";
import {AppTableBasePagination} from "common/table/types/onChange";
import {InquiryOrderDTO} from "types/entities/InquiryOrderDTO";
import {UdcLbcRequestsState} from "./types";

const initialState: UdcLbcRequestsState = {
  tableState: {
    pagination: {
      current: DEFAULT_PAGINATION.current,
      total: DEFAULT_PAGINATION.total,
      pageSize: DEFAULT_PAGINATION.pageSize,
    },
  },
  filters: {
    onlyPersonal: false,
  },
  elements: [],
  loading: true,
};

const udcLbcRequestsSlice = createSlice({
  name: "udcLbcRequests",
  initialState,
  reducers: {
    setOnlyPersonal: (state: UdcLbcRequestsState, action: PayloadAction<boolean>) => {
      state.filters.onlyPersonal = action.payload;
    },
    setTableState: (state: UdcLbcRequestsState, action: PayloadAction<{pagination: AppTableBasePagination}>) => {
      state.tableState.pagination = action.payload.pagination;
    },
    resetCurrentPage: (state: UdcLbcRequestsState) => {
      state.tableState.pagination.current = DEFAULT_PAGINATION.current;
    },
    rejectedByError: (state: UdcLbcRequestsState) => {
      state.loading = false;
    },
    clear: () => initialState,
  },
  extraReducers: {
    [fetchUdcLbcRequestsList.pending.type]: (state: UdcLbcRequestsState) => {
      state.loading = true;
    },
    [fetchUdcLbcRequestsList.fulfilled.type]: (state: UdcLbcRequestsState, action: PayloadAction<InquiryOrderDTO[]>) => {
      state.elements = action.payload;
      state.tableState.pagination.total = action.payload.length;
      state.loading = false;
    },
    [udcLbcRequestAppended.type]: (state: UdcLbcRequestsState, action: PayloadAction<UdcLbcRequestAppendedResult>) => {
      state.elements = action.payload.elements;
      state.tableState.pagination.total = action.payload.total;
    }
  }
});

export const udcLbcRequestsReducer = udcLbcRequestsSlice.reducer;
export const udcLbcRequestsActions = udcLbcRequestsSlice.actions;