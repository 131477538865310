import {SEARCH_PAGE_PATH} from "app/routing/search/constants";
import {TSearchParameters} from "types/search-params";

export const buildURL = (searchParameters: TSearchParameters): URL => {
  try {
    const url = new URL(window.location.origin + SEARCH_PAGE_PATH);
    Object.keys(searchParameters).forEach(key => {
      const value = searchParameters[key];
      if (value) {
        url.searchParams.set(key, value);
      }
    });

    return url;
  } catch {
    return new URL(window.location.href);
  }
};