import {getBasket} from "app/actions/basket/basket";
import {getPurchases} from "app/actions/purchases/purchases";
import {useAppNavigation} from "app/routing/use-navigation/use-navigation";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect} from "react";
import {useDispatch} from "react-redux";

export const usePurchasesPage = () => {
  const dispatch = useDispatch();
  const {profileState} = useAppSelector((state) => state);
  const {purchaseItems, loading} = useAppSelector(
    (state) => state.purchasesState
  );

  const {toHome} = useAppNavigation();

  useEffect(() => {
    if (profileState.profile === null) {
      toHome();
    }
  }, [profileState.profile]);

  useEffect(() => {
    dispatch(getPurchases(profileState.profile?.id || " "));
    dispatch(getBasket(profileState.profile?.id || " "));
  }, []);

  return {
    state: {
      items: purchaseItems,
      loading: loading,
    },
  };
};
