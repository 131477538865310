import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getResourceById, reserve} from "app/actions/resource/resource";
import {ResourceByIdResponse} from "rest/resource/types";
import {ResourceElement} from "../search/types";
import {CurrentElementState} from "./types";

const initialState: CurrentElementState = {
  content: {
    id: "",
  },
  similarElements: [],
  loading: true,
  error: "",
};

const currentElementSlice = createSlice({
  name: "currentElement",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    [getResourceById.pending.type]: () => initialState,
    [getResourceById.rejected.type]: (state: CurrentElementState) => {
      state.error = "При загрузке контента произошла ошибка.";
      state.loading = false;
    },
    [getResourceById.fulfilled.type]: (
      state: CurrentElementState,
      action: PayloadAction<ResourceByIdResponse>
    ) => {
      state.content = action.payload.content;
      state.similarElements = action.payload.elements;
      state.error = "";
      state.loading = false;
    },
    [reserve.fulfilled.type]: (
      state: CurrentElementState,
      action: PayloadAction<ResourceElement>
    ) => {
      state.content = action.payload;
    },
  },
});

export const {
  reducer: currentElementReducer,
  actions: currentElementActions,
} = currentElementSlice;
