import {ACCESS_TOKEN_NAME, CUBA_APP_URL} from "api-config";
import {FileDescriptor} from "types/common";

export const getDownloadUrl = (fileDescriptor?: FileDescriptor) => {
  if (!fileDescriptor) {
    return "";
  }
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  const cubaDownloadUrl = `${CUBA_APP_URL}v2/files/${fileDescriptor.id}?access_token=${token}&attachment=true`;
  return cubaDownloadUrl;
};