import {Badge} from "antd";
import {useAppSelector} from "app/store/hooks/use-selector";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {useBasket} from "./hooks/useBasket";
import {ShoppingCart} from "common/icons/ShoppingCart";
import {AppSpacer} from "common/spacer/Spacer";
import {AppTitle} from "common/title/Title";

import styles from "./styles.module.scss";

export const Basket = () => {
  const {hide, active, onClick} = useBasket();
  const {basketItems} = useAppSelector((state) => state.basketState);

  const basketClassName = getClassNames(
    styles.basket,
    active && styles["basket--active"]
  );

  if (hide) {
    return null;
  }

  return (
    <AppSpacer className={styles.container} onClick={onClick}>
      <div className={basketClassName}>
        <Badge count={basketItems.length} offset={[-40, 10]} color={"#1673CA"}>
          <ShoppingCart stroke={active ? "#FFFFFF" : "#A4A4A4"}/>
        </Badge>
      </div>
      <AppTitle level={4} className={styles.title}>
        Корзина
      </AppTitle>
    </AppSpacer>
  );
};
