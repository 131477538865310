import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DEFAULT_PAGINATION} from "common/table/utils/default-pagination";
import {AppTableBasePagination} from "common/table/types/onChange";
import {StatementRequestsState} from "./types";
import {fetchStatementRequestsList} from "app/actions/statement-request/statement-request-list";
import {StatementRequestDTO} from "types/entities/StatementRequestDTO";
import {createStatementRequest} from "app/actions/statement-request/create-request";

const initialState: StatementRequestsState = {
  tableState: {
    pagination: {
      current: DEFAULT_PAGINATION.current,
      total: DEFAULT_PAGINATION.total,
      pageSize: DEFAULT_PAGINATION.pageSize,
    },
  },
  filters: {
    onlyPersonal: false,
  },
  elements: [],
  loading: true,
};

const statementRequestsSlice = createSlice({
  name: "statementRequests",
  initialState,
  reducers: {
    setOnlyPersonal: (state: StatementRequestsState, action: PayloadAction<boolean>) => {
      state.filters.onlyPersonal = action.payload;
    },
    setTableState: (state: StatementRequestsState, action: PayloadAction<{pagination: AppTableBasePagination}>) => {
      state.tableState.pagination = action.payload.pagination;
    },
    resetCurrentPage: (state: StatementRequestsState) => {
      state.tableState.pagination.current = DEFAULT_PAGINATION.current;
    },
    rejectedByError: (state: StatementRequestsState) => {
      state.loading = false;
    },
    clear: () => initialState,
  },
  extraReducers: {
    [fetchStatementRequestsList.pending.type]: (state: StatementRequestsState) => {
      state.loading = true;
    },
    [fetchStatementRequestsList.fulfilled.type]: (state: StatementRequestsState, action: PayloadAction<StatementRequestDTO[]>) => {
      state.elements = action.payload;
      state.tableState.pagination.total = action.payload.length;
      state.loading = false;
    },
    [createStatementRequest.fulfilled.type]: (state: StatementRequestsState, action: PayloadAction<StatementRequestDTO | null>) => {
      if (action.payload) {
        state.elements = [action.payload, ...state.elements];
        state.tableState.pagination.total = state.tableState.pagination.total || 0 + 1;
      }
    },
  }
});

export const statementRequestsReducer = statementRequestsSlice.reducer;
export const statementRequestsActions = statementRequestsSlice.actions;