import {ButtonProps} from "antd/lib/button/button";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {AppButton} from "../Button";
import styles from "./styles.module.scss";

export const AppOutlineButton = (props: ButtonProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles["btn-outline"], className);

  return (
    <AppButton className={classNames} {...rest}/>
  );
};