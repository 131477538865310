import {useAppSelector} from "app/store/hooks/use-selector";
import {useCallback} from "react";
import {useNavigate} from "react-router";
import {shallowEqual} from "react-redux";
import {debounce} from "throttle-debounce";
import {findContent} from "app/actions/search/content";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {SEARCH_PAGE_PATH} from "app/routing/search/constants";
import {TSearchParameters} from "types/search-params";
import {
  getURL,
  getSearchPageLink,
  getURLSearchParameters,
} from "utils/search/helpers";

const DEBOUNCE_DELAY = 200;

export const useSearch = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {profileState} = useAppSelector((state) => state);

  return useCallback(
    debounce(DEBOUNCE_DELAY, (nextParams: TSearchParameters) => {
      const {
        contentId,
        contentTypeId,
        limit,
        offset,
        searchBy,
        searchString,
        ...dinamycFiltersValues
      } = nextParams;

      const {pathname} = getURL();
      const currentParams = getURLSearchParameters();
      const filters = Object.keys(dinamycFiltersValues).map((key) => {
        return {
          id: key,
          value: dinamycFiltersValues[key],
        };
      });

      if (
        pathname === SEARCH_PAGE_PATH &&
        shallowEqual(currentParams, nextParams)
      ) {
        dispatch(
          findContent({
            searchParameters: {
              contentId,
              contentTypeId,
              limit,
              offset,
              searchBy,
              searchString,
              filters,
            },
            userId: profileState.profile?.id,
          })
        );
      } else {
        navigate(getSearchPageLink(nextParams));
      }
    }),
    []
  );
};
