import {AppLoader} from "common/loader/Loader";
import {AppSpacer} from "common/spacer/Spacer";
import {AddCommentModal} from "./add-comment-modal/AddCommentModal";
import {CommentsPanel} from "./comments-panel/CommentsPanel";
import {useComments} from "./hooks/useComments";

type CommentsProps = {
  price?: string | null;
};

export const Comments = (props: CommentsProps) => {
  const {state, methods} = useComments(props.price);

  return state.loading ? (
    <AppSpacer alignItems="center">
      <AppLoader/>
    </AppSpacer>
  ) : (
    <>
      <CommentsPanel
        openModal={methods.openModal}
        comments={state.comments}
        hideAddComment={state.hideAddComment}
      />
      <AddCommentModal
        open={state.modalIsOpen}
        closeModal={methods.closeModal}
        onChangeText={methods.setFormValue}
        onChangeRating={methods.setRating}
        createComment={methods.addComment}
      />
    </>
  );
};
