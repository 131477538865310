import {notification} from "antd";
import axios, {AxiosError} from "axios";

export const errorHandler = (message: string, showNotification = true) => (error: AxiosError) => {
  if (axios.isCancel(error)) {
    throw error;
  }

  if (error.code !== "401" && showNotification) {
    notification.error({message});
  }
  throw new Error(message);
};