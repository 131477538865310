import {changeBasketItem, getBasket} from "app/actions/basket/basket";
import {useAppNavigation} from "app/routing/use-navigation/use-navigation";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {UpdateBasketItemParams} from "rest/basket/types";
import {makePurchase} from "rest/purchase/makePurchase";

export const useBasketPage = () => {
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);

  const dispatch = useDispatch();
  const {profileState} = useAppSelector((state) => state);
  const {basketItems, loading, error} = useAppSelector(
    (state) => state.basketState
  );

  const {toHome} = useAppNavigation();

  useEffect(() => {
    if (profileState.profile === null) {
      toHome();
    }
  }, [profileState.profile]);

  useEffect(() => {
    if (profileState.profile) {
      const action = getBasket(profileState.profile?.id || " ");

      dispatch(action);
    }
  }, []);

  useEffect(() => {
    const selectedItems = basketItems
      .filter((item) => item.selected)
      .map((selected) => {
        return selected.content.id;
      });

    setSelectedItemsIds(selectedItems);
  }, [basketItems]);

  const onClickCheckbox = (id: string) => {
    if (profileState) {
      const basketItem = basketItems.find((item) => item.content.id === id);

      const params: UpdateBasketItemParams = {
        selected: !basketItem?.selected,
        id: basketItem?.id || "",
      };

      const action = changeBasketItem(params);

      dispatch(action);
    }
  };

  const allSelected = () => selectedItemsIds.length === basketItems.length;

  const onClickCheckAll = () => {
    if (allSelected()) {
      basketItems.forEach((item) => {
        onClickCheckbox(item.content.id);
      });
    } else {
      basketItems.forEach((item) => {
        if (item.selected) {
          return;
        }
        onClickCheckbox(item.content.id);
      });
    }
  };

  const getTotalAmount = () => {
    const filtered = basketItems.filter((basketItem) =>
      selectedItemsIds.includes(basketItem.content.id)
    );

    return filtered.reduce((acc, item) => {
      acc += Number(item.content.price) * item.amount;
      return acc;
    }, 0);
  };

  const onBuyClick = () => {
    const filtered = basketItems.filter((basketItem) =>
      selectedItemsIds.includes(basketItem.content.id)
    );
    const params = {
      amount: getTotalAmount(),
      userId: profileState.profile?.id || "",
      basketItemIds: filtered.map((item) => item.id),
    };

    makePurchase(params).then((res) => {
      window.open(res);
    });
  };

  return {
    state: {
      items: basketItems,
      selectedItemsIds,
      loading,
      error,
      allSelected: allSelected(),
      total: {
        totalCount: selectedItemsIds.length,
        totalAmount: getTotalAmount(),
      },
    },
    methods: {
      onClickCheckbox,
      onClickCheckAll,
      onBuyClick,
    },
  };
};
