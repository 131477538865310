import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  addBasketItem,
  changeBasketItem,
  getBasket,
  removeBasketItem,
} from "app/actions/basket/basket";
import {
  BasketItem,
  BasketItemStatus,
  BasketState,
} from "app/reducers/basket/types";
import {GetBasketContentResponse} from "rest/basket/types";

const initialState: BasketState = {
  basketItems: [],
  loading: true,
  error: "",
};

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    [getBasket.pending.type]: () => initialState,
    [getBasket.rejected.type]: (state: BasketState) => {
      state.error = "При зарузке корзины произошла ошибка";
      state.loading = false;
    },
    [getBasket.fulfilled.type]: (
      state: BasketState,
      action: PayloadAction<GetBasketContentResponse>
    ) => {
      state.basketItems = action.payload;
      state.loading = false;
      state.error = "";
    },
    [removeBasketItem.fulfilled.type]: (
      state: BasketState,
      action: PayloadAction<string>
    ) => {
      state.basketItems = state.basketItems.filter(
        (item) => item.id !== action.payload
      );
      state.loading = false;
      state.error = "";
    },
    [addBasketItem.fulfilled.type]: (
      state: BasketState,
      action: PayloadAction<BasketItem>
    ) => {
      state.basketItems.push(action.payload);
      state.loading = false;
      state.error = "";
    },
    [changeBasketItem.fulfilled.type]: (
      state: BasketState,
      action: PayloadAction<BasketItem>
    ) => {
      const newItemIdx = state.basketItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (action.payload.status === BasketItemStatus.DELETED) {
        state.basketItems = state.basketItems.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        state.basketItems.splice(newItemIdx, 1, action.payload);
      }

      state.loading = false;
      state.error = "";
    },
  },
});

export const {reducer: basketReducer, actions: basketActions} = basketSlice;
