import {FormInstance} from "antd/lib/form";
import {ResourceElement} from "app/reducers/search/types";
import moment, {Moment} from "moment";
import {reserveFormFields} from "../fields";

export const endDateValidator = (form: FormInstance, element: ResourceElement) => {
  return (_: any, currentValue: Moment) => {
    if (!currentValue) {
      return Promise.reject("Обязательное поле");
    }

    const startDateFormValue = moment(form.getFieldValue(reserveFormFields.reserveStarts));

    if (element.availableReserveStarts && currentValue < moment(element.availableReserveStarts)) {
      return Promise.reject("Выбранная дата не входит в допустимый диапазон");
    }

    if (element.availableReserveEnds && currentValue > moment(element.availableReserveEnds)) {
      return Promise.reject("Выбранная дата не входит в допустимый диапазон");
    }

    if (!startDateFormValue) {
      return Promise.resolve();
    }

    if (currentValue < startDateFormValue) {
      return Promise.reject("Дата окончания меньше даты начала");
    }

    return Promise.resolve();
  };
};