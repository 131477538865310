import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchPublishedVirtualExhibition, fetchVirtualExhibitionById} from "app/actions/exhibitions/exhibitions";
import {ExhibitionItem, VirtualExhibitionResponse, VirtualExhibitionState} from "./types";

const initialState: VirtualExhibitionState = {
  elements: [],
  selectedElement: null,
  loading: true,
};

const virtualExhibitionSlice = createSlice({
  name: "virtualExhibition",
  initialState,
  reducers: {
    clearElements: (state: VirtualExhibitionState) => {
      state.elements = [];
      state.loading = true;
    },
    clearSelectedElement: (state: VirtualExhibitionState) => {
      state.selectedElement = null;
    },
  },
  extraReducers: {
    [fetchPublishedVirtualExhibition.pending.type]: (state: VirtualExhibitionState) => {
      state.loading = true;
    },
    [fetchPublishedVirtualExhibition.rejected.type]: (state: VirtualExhibitionState) => {
      state.loading = false;
    },
    [fetchPublishedVirtualExhibition.fulfilled.type]: (state: VirtualExhibitionState, action: PayloadAction<VirtualExhibitionResponse>) => {
      state.elements = action.payload.elements;
      state.loading = false;
    },
    [fetchVirtualExhibitionById.fulfilled.type]: (state: VirtualExhibitionState, action: PayloadAction<ExhibitionItem>) => {
      state.selectedElement = action.payload;
    },
  }
});

export const virtualExhibitionReducer = virtualExhibitionSlice.reducer;
export const {clearElements, clearSelectedElement} = virtualExhibitionSlice.actions;
