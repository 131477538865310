export enum ESearchParameters {
  contentId = "contentId",
  contentTypeId = "contentTypeId",
  limit = "limit",
  offset = "offset",
  searchBy = "searchBy",
  searchString = "searchString",
}

export type TSearchParameters = Partial<Record<keyof typeof ESearchParameters, string | null> & {[key: string]: any;}>;

export const SEARCH_PARAMETER_NAMES = Object.keys(ESearchParameters) as ReadonlyArray<ESearchParameters>;

export const NULLIFIED_SEARCH_PARAMETERS = SEARCH_PARAMETER_NAMES
  .reduce((acc, curr) => {
      acc[curr] = null;
      return acc;
    }, {} as TSearchParameters
  ) as Readonly<TSearchParameters>;