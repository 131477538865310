import {appApi} from "api-config";
import {ExhibitionItem} from "app/reducers/exhibitions/types";
import {errorHandler} from "utils/error-handler";

export const getPublishedVirtualExhibitions = (): Promise<ExhibitionItem[]> => {
  return appApi
    .post("v2/services/educationalportal_VirtualExhibitionService/getPublishedVirtualExhibitions", {
      searchParameters: {}
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось загрузить виртуальную выставку"));
};