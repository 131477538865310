import {AppLink} from "common/link/Link";
import {ArrowLeftIcon} from "common/icons/ArrowLeft";
import {AppSpacer} from "common/spacer/Spacer";
import styles from "./styles.module.scss";

type ButtonPreviousProps = {
  to: string;
  title: string;
}

export const LinkPrevious = (props: ButtonPreviousProps) => {
  return (
    <AppLink to={props.to} className={styles.link}>
      <AppSpacer direction="horizontal" unit="8" alignItems="center">
        <ArrowLeftIcon/>
        <span className={styles["link-title"]}>{props.title}</span>
      </AppSpacer>
    </AppLink>
  );
};