import {useAddToBasket} from "app/components/common/add-to-basket/hooks/useAddToBasket";
import {ResourceElement} from "app/reducers/search/types";
import {IconButton} from "common/button/icon-button/IconButton";
import {RemoveFromBasket} from "common/icons/RemoveFromBasket";
import {ShoppingCart} from "common/icons/ShoppingCart";
import {AppSpacer} from "common/spacer/Spacer";
import {AppTooltip} from "common/tooltip/Tooltip";
import React from "react";
import {getClassNames} from "utils/get-class-names/get-class-names";

import styles from "./styles.module.scss";

type AddToBasketProps = {
  resourceElement: ResourceElement;
  showTooltip?: boolean;
  showText?: boolean;
  isBasketItem?: boolean;
};

export const AddToBasket = (props: AddToBasketProps) => {
  const {
    showText,
    showTooltip,
    resourceElement,
    isBasketItem = false,
  } = props;

  const {isInBasket, onClick, hideButton} = useAddToBasket(resourceElement);
  const wrapperClassName = getClassNames(
    styles["to-basket"],
    showText && styles["to-basket--with-text"]
  );

  const text = isInBasket ? "Уже в корзине" : "Добавить в корзину";

  if (hideButton) {
    return null;
  }

  const getContent = () => {
    return (
      <AppSpacer
        className={wrapperClassName}
        direction="horizontal"
        onClick={onClick}
      >
        <IconButton className={styles.button}>
          {!isBasketItem ? (
            <ShoppingCart fill={isInBasket ? "#3575C7" : "none"}/>
          ) : (
            <RemoveFromBasket/>
          )}
        </IconButton>
        {showText && text}
      </AppSpacer>
    );
  };

  return showTooltip ? (
    <AppTooltip placement="top" title={text}>
      {getContent()}
    </AppTooltip>
  ) : (
    getContent()
  );
};
