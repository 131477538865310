import {ContentCard} from "app/components/search/content-card/ContentCard";
import {BasketItem} from "app/reducers/basket/types";
import {AppCheckbox} from "common/checkbox/Checkbox";
import {AppSpacer} from "common/spacer/Spacer";
import {AppTitle} from "common/title/Title";
import {modifyResourceVersion} from "utils/resource/modifyResourceVersion";

import styles from "./styles.module.scss";

type BasketContentProps = {
  items: BasketItem[];
  selectedItems: string[];
  onClickCheckbox: (id: string) => void;
  onClickSelectAll: () => void;
  allSelected: boolean;
};

export const BasketContent = (props: BasketContentProps) => {
  return (
    <AppSpacer unit="50" className={styles.container}>
      <AppSpacer
        direction="horizontal"
        alignItems="center"
        unit="20"
        className={styles["title-container"]}
      >
        <AppTitle level={1} className={styles.title}>
          Корзина
        </AppTitle>
        <AppCheckbox
          className={styles.checkbox}
          onClick={props.onClickSelectAll}
          checked={props.allSelected}
        >
          Выбрать все
        </AppCheckbox>
      </AppSpacer>
      <AppSpacer unit="10">
        {props.items.map((basketItem) => {
          const element = modifyResourceVersion(basketItem.content);
          const selected = props.selectedItems.includes(element.id);

          return (
            <ContentCard
              key={basketItem.id}
              element={element}
              isBasketItem
              selected={selected}
              onClickCheckBox={props.onClickCheckbox}
            />
          );
        })}
      </AppSpacer>
    </AppSpacer>
  );
};
