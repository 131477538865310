import axios, {CancelTokenSource} from "axios";
import {appApi} from "api-config";
import {InquiryOrderDTO} from "types/entities/InquiryOrderDTO";
import {errorHandler} from "utils/error-handler";

export type VirtualReferenceListParams = {
  id: string | null;
  onlyPersonal: boolean;
  limit: number;
  offset: number;
}

const _getVirtualReferenceList = () => {
  let source: CancelTokenSource | undefined;

  return (params: VirtualReferenceListParams): Promise<InquiryOrderDTO[]> => {
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();

    return appApi
      .post(
        "v2/services/educationalportal_RestInquiryOrderService/getOrderList",
        {id: params.id, onlyPersonal: params.onlyPersonal},
        {cancelToken: source.token}
      )
      .then(res => res.data)
      .catch(errorHandler("Не удалось загрузить реестр виртуальных справок"));
  };
};

export const getVirtualReferenceList = _getVirtualReferenceList();