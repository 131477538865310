import {AppOutlineButton} from "common/button/outline/Outline";
import {AppSpacer} from "common/spacer/Spacer";
import styles from "./styles.module.scss";

export type ReserveModalProps = {
  loading: boolean;
  applyForReserve(): void;
  hide(): void;
}

export const ReserveModalButtons = (props: ReserveModalProps) => {
  return (
    <AppSpacer unit="12" direction="horizontal"
               justifyContent="flex-end"
               className={styles["button-container"]}>
      <AppOutlineButton loading={props.loading}
                        disabled={props.loading}
                        onClick={props.applyForReserve}>
        Зарезервировать
      </AppOutlineButton>
      <AppOutlineButton onClick={props.hide} disabled={props.loading}>
        Отмена
      </AppOutlineButton>
    </AppSpacer>
  );
};