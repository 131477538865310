import {NavLink, NavLinkProps} from "react-router-dom";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

type AppLinkProps = Omit<NavLinkProps, "className"> & {
  activeClassName?: string;
  className?: string;
}

export const AppLink = (props: AppLinkProps) => {
  const {className, activeClassName, ...rest} = props;
  const classNames = getClassNames(styles.link, className);

  const getActiveClassName = (isActive: boolean) => {
    return isActive ? `${getClassNames(classNames, activeClassName)}` : classNames;
  };

  return (
    <NavLink {...rest} className={({isActive}) => getActiveClassName(isActive)}/>
  );
};