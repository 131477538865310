import {BASKET_PAGE_PATH} from "app/routing/basket/constants";
import {PURCHASES_PAGE_PATH} from "app/routing/purchases/constants";
import {useNavigate} from "react-router-dom";
import {replacePathParams} from "utils/replace-path-params/replace-path-params";
import {RESOURCE_PAGE_PATH} from "app/routing/resource/constants";
import {SEARCH_PAGE_PATH} from "app/routing/search/constants";
import {HOME_PAGE_PATH} from "app/routing/home/constants";
import {UseAppNavigationService} from "./types";
import {GO_BACK_PATH} from "./constants";

export const useAppNavigation = (): UseAppNavigationService => {
  const navigate = useNavigate();

  const toHome = () => navigate(HOME_PAGE_PATH);
  const goBack = () => navigate(GO_BACK_PATH);

  const toSearch = (query: string) => {
    console.log(query);
    navigate(SEARCH_PAGE_PATH);
  };

  const toResource = (id: string) => {
    const resourceUrl = replacePathParams(RESOURCE_PAGE_PATH, [id]);
    navigate(resourceUrl);
  };

  const toBasket = () => navigate(BASKET_PAGE_PATH);

  const toPurchases = () => navigate(PURCHASES_PAGE_PATH);

  return {
    toHome,
    goBack,
    toSearch,
    toResource,
    toBasket,
    toPurchases,
  };
};
