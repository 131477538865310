import {appApi} from "api-config";
import {BasketItem} from "app/reducers/basket/types";
import {UpdateBasketItemParams} from "rest/basket/types";
import {errorHandler} from "utils/error-handler";

export const updateBasketItem = (
  params: UpdateBasketItemParams
): Promise<BasketItem> => {
  return appApi
    .put(
      "/portal/basket_item",
      {...params, id: undefined},
      {
        params: {
          basketItemId: params.id,
        },
      }
    )
    .then((res) => res.data)
    .catch(errorHandler("Не удалось обновить товар в корзине"));
};
