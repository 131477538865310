import {appApi} from "api-config";
import {ResourceElement} from "app/reducers/search/types";
import {errorHandler} from "utils/error-handler";

export type ReserveDTO = {
  userId: string;
  contentId: string;
  reserveStarts: string;
  reserveEnds: string;
}

export const reserveContent = (reserveDTO: ReserveDTO): Promise<ResourceElement> => {
  return appApi
    .post("v2/services/educationalportal_ContentReservationService/reserveContent", {
      reserveDTO,
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось зарезервировать"));
};