import {useChangeAmount} from "app/components/common/change-amount/hook/useChageAmount";
import {AppPrimaryButton} from "common/button/primary/Primary";
import {AppInput} from "common/input/Input";
import {AppSpacer} from "common/spacer/Spacer";

import styles from "./styles.module.scss";

type ChangeAmountProps = {
  contentId: string;
};

export const ChangeAmount = (props: ChangeAmountProps) => {
  const {amount, decrement, increment} = useChangeAmount(props.contentId);

  return (
    <AppSpacer className={styles.container} direction="horizontal">
      <AppPrimaryButton onClick={decrement} className={styles.button}>
        -
      </AppPrimaryButton>
      <AppInput className={styles.input} readOnly value={amount}/>
      <AppPrimaryButton onClick={increment} className={styles.button}>
        +
      </AppPrimaryButton>
    </AppSpacer>
  );
};
