import {Typography} from "antd";
import {TitleProps} from "antd/lib/typography/Title";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

export const AppTitle = (props: TitleProps) => {
  const {className, level = 5, ...rest} = props;
  const classNames = getClassNames(styles.title, getClassByLevel(level), className);

  return (
    <Typography.Title {...rest} level={level}
                      className={classNames}>
      {props.children}
    </Typography.Title>
  );
};

const getClassByLevel = (level = 5): string => {
  return styles[`title-${level}`];
};