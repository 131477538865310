import {getBasket} from "app/actions/basket/basket";
import {findContent} from "app/actions/search/content";
import {useEffect} from "react";
import {getNewAndPopularContents} from "app/actions/new-popular/new-popular";
import {newAndPopularContentActions} from "app/reducers/new-and-popular";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useAppSelector} from "app/store/hooks/use-selector";

export const useHomePage = () => {
  const {newAndPopularContentState} = useAppSelector((state) => state);
  const searchState = useAppSelector((state) => state.searchContentState);

  const dispatch = useAppDispatch();
  const {profileState} = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(
      findContent({
        searchParameters: {},
        userId: profileState.profile?.id,
      })
    );
    if (profileState.profile !== null) {
      dispatch(getBasket(profileState.profile?.id || " "));
    }
  }, []);

  useEffect(() => {
    dispatch(getNewAndPopularContents(profileState.profile?.id));

    return () => {
      dispatch(newAndPopularContentActions.clear());
    };
  }, []);

  return {
    popularContent: newAndPopularContentState.popularContents.slice(0, 3),
    newContent: newAndPopularContentState.newContents.slice(0, 3),
    loading: newAndPopularContentState.loading || searchState.loading,
    searchState,
  };
};
