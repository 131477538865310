import moment from "moment";
import {useForm} from "antd/lib/form/Form";
import {reserve} from "app/actions/resource/resource";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useEffect, useState} from "react";
import {ReserveDTO} from "rest/resource/reserve";
import {USER_DATE_FORMAT} from "utils/date/date";
import {ReserveModalProps} from "../Reserve";
import {ReserveFormValues} from "../../reserve-form/types";

export const useReserveModal = (props: ReserveModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = useForm<ReserveFormValues>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.element && props.visible) {
      setInitialValues();
    }
  }, [props.element, props.visible]);

  const setInitialValues = () => {
    form.setFieldsValue({
      reserveStarts: props.element.availableReserveStarts && moment(props.element.availableReserveStarts),
      reserveEnds: props.element.availableReserveEnds && moment(props.element.availableReserveEnds),
    });
  };


  const applyForReserve = () => {
    form.validateFields().then(submit);
  };

  const submit = (values: ReserveFormValues) => {
    setLoading(true);
    const reserveDto: ReserveDTO = {
      userId: props.profile.id!,
      contentId: props.element.id,
      reserveStarts: values.reserveStarts.format(USER_DATE_FORMAT),
      reserveEnds: values.reserveEnds.format(USER_DATE_FORMAT),
    };
    dispatch(reserve(reserveDto))
      .unwrap()
      .then(() => props.hide())
      .finally(() => setLoading(false));
  };

  return {
    state: {
      form,
      loading,
    },
    methods: {
      applyForReserve
    }
  };
};