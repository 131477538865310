import React from "react";
import {AppLoaderPlaceholder} from "common/loader-placeholder/LoaderPlacehlder";
import {WithClassName} from "types/props";

type WithLoadingProps = WithClassName & {
  loading: boolean;
}

export const withLoading = <P,>(WrappedComponent: React.FunctionComponent<P>) => {
  return (props: P & WithLoadingProps) => {
    if (props.loading) {
      return (
        <AppLoaderPlaceholder className={props.className}/>
      );
    }

    return (
      <WrappedComponent {...props}/>
    );
  };
};