import {useKeycloak} from "@react-keycloak/web";
import {addBasketItem, changeBasketItem} from "app/actions/basket/basket";
import {BasketItem, BasketItemStatus} from "app/reducers/basket/types";
import {ResourceElement} from "app/reducers/search/types";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddToBasketRequestParams} from "rest/basket/types";

export const useAddToBasket = (resourceElement: ResourceElement) => {
  const [basketItem, setBasketItem] = useState<BasketItem | null>(null);
  const [isInBasket, setIsInBasket] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {keycloak} = useKeycloak();
  const {profileState} = useAppSelector((state) => state);
  const {basketItems} = useAppSelector((state) => state.basketState);
  const {purchaseItems} = useAppSelector((state) => state.purchasesState);

  useEffect(() => {
    const item = basketItems.find((element) => {
      return element.content.id === resourceElement.id;
    });
    if (item) {
      setBasketItem(item);
      setIsInBasket(true);
    } else {
      setIsInBasket(false);
    }
  }, [basketItems]);

  const addToBasket = () => {
    const payload: AddToBasketRequestParams = {
      amount: 1,
      content: {
        id: resourceElement.id,
      },
      price: Number(resourceElement.price) || 0,
      selected: true,
      userId: profileState.profile?.id || " ",
    };

    const action = addBasketItem(payload);
    dispatch(action);
  };

  const removeFromBasket = () => {
    if (basketItem) {
      const params = {
        id: basketItem.id,
        status: BasketItemStatus.DELETED,
      };

      const action = changeBasketItem(params);

      dispatch(action);
    }
  };

  const onClick = () => {
    if (isInBasket) {
      removeFromBasket();
    } else {
      addToBasket();
    }
  };

  const userBoughtProduct =
    purchaseItems.findIndex(
      (item) => item.content.id === resourceElement.id
    ) !== -1;

  return {
    hideButton:
      !keycloak.authenticated ||
      (userBoughtProduct && !resourceElement.multiple),
    isInBasket,
    onClick,
  };
};
