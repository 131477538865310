import {IframeProps} from "types/props";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

export const AppIframe = (props: IframeProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles.iframe, className);

  return (
    <iframe {...rest} className={classNames}/>
  );
};