import {WithClassName} from "types/props";
import {AppSpacer} from "../spacer/Spacer";
import {AppLoader} from "../loader/Loader";
import styles from "./styles.module.scss";

type AppLoaderPlaceholderProps = WithClassName;

export const AppLoaderPlaceholder = (props: AppLoaderPlaceholderProps) => {
  const className = `${styles.container} ${props.className}`;

  return (
    <AppSpacer className={className}
               alignItems="center"
               justifyContent="center">
      <AppLoader/>
    </AppSpacer>
  );
};