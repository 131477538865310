import {SvgProps} from "types/props";

export const SearchIcon = (props: SvgProps) => {
  const {stroke = "#C5C5C5", ...rest} = props;

  return (
    <svg {...rest} width="1.8rem" height="1.8rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 17L10.5 10.5M12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5Z"
            stroke={stroke} strokeWidth="1.5"/>
    </svg>
  );
};