import {TablePaginationConfig} from "antd/lib/table";
import {showTotal} from "common/pagination/utils/show-total";

export const DEFAULT_PAGINATION: TablePaginationConfig = {
  total: 0,
  current: 1,
  pageSize: 10,
  defaultPageSize: 10,
  position: ["bottomRight"],
  showSizeChanger: false,
  hideOnSinglePage: true,
  showTotal,
};