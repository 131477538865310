import React from "react";
import {AppSpacer} from "common/spacer/Spacer";
import {AppSearchInput} from "common/input/search/SearchInput";
import {AppSelect} from "common/select/single-options/SingleSelect";
import {AppSelectOption} from "common/select/types";
import {ArrowDownIcon} from "common/icons/ArrowDown";
import styles from "./styles.module.scss";

type SearchContainerProps = {
  inputValue: string;
  selectValue: string;
  selectOptions: AppSelectOption[],
  onInputChange(value: string): void;
  onSelectChange(id: string): void;
  onSearch(): void;
}

export const SearchContainer = (props: SearchContainerProps) => {
  const onClear = () => {
    props.onInputChange("");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onInputChange(event.target.value);
  };

  return (
    <AppSpacer direction="horizontal" unit="8"
               className={styles.container}>
      <AppSearchInput className={styles["search-input"]}
                      onSearch={props.onSearch}
                      onClear={onClear}
                      onChange={onChange}
                      value={props.inputValue}/>
      <AppSelect className={styles["search-select"]}
                 options={props.selectOptions}
                 value={props.selectValue}
                 onChange={props.onSelectChange}
                 placeholder="Искать по"
                 allowClear={false} showSearch={false}
                 suffixIcon={<ArrowDownIcon/>}/>
    </AppSpacer>
  );
};