import {Basket} from "app/components/header/basket/Basket";
import {HeaderLinks} from "app/components/header/links/Links";
import {Profile} from "app/components/header/profile/Profile";
import {Logo} from "app/components/header/logo/Logo";
import {AppSpacer} from "common/spacer/Spacer";

import styles from "./styles.module.scss";

export const AppHeader = () => {
  return (
    <header className={styles.header}>
      <AppSpacer
        className={styles.spacer}
        direction="horizontal"
        justifyContent="flex-start"
      >
        <Logo/>
        <AppSpacer unit="0" direction="horizontal">
          <HeaderLinks/>
        </AppSpacer>
      </AppSpacer>
      <AppSpacer
        alignItems="flex-start"
        direction="horizontal"
        justifyContent="space-between"
      >
        <Basket/>
        <Profile/>
      </AppSpacer>
    </header>
  );
};
