import {Moment, unitOfTime} from "moment";

export const inRange = (
  current?: Moment | null,
  minDate?: Moment | null,
  maxDate?: Moment | null,
  granularity: unitOfTime.StartOf = "day",
) => {
  if (!current) {
    return true;
  }

  if (minDate && !maxDate) {
    return current.isAfter(minDate, granularity);
  }

  if (!minDate && maxDate) {
    return maxDate.isAfter(current, granularity);
  }

  if (minDate && maxDate) {
    return current.isAfter(minDate, granularity) && maxDate.isAfter(current, granularity);
  }

  return true;
};