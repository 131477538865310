import {ModalProps} from "antd/lib/modal";
import {Modal} from "antd";
import {WithChildren} from "types/props";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {AppLoader} from "common/loader/Loader";
import {AppSpacer} from "common/spacer/Spacer";
import styles from "./styles.module.scss";

export type AppModalProps = ModalProps & WithChildren & {
  loading?: boolean;
};

export const AppModal = (props: AppModalProps) => {
  const className = getClassNames(styles.modal, props.className);

  return (
    <Modal footer={null} {...props} className={className}>
      {
        props.loading ? (
          <AppSpacer alignItems="center"
                     justifyContent="center"
                     className={styles.loader}>
            <AppLoader/>
          </AppSpacer>
        ) : (
          <>
            {props.children}
          </>
        )
      }
    </Modal>
  );
};
