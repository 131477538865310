import {getHighlightedText} from "utils/highlight/get-highlighted-text/get-highlighted-text";
import {HighlightedText} from "utils/highlight/get-highlighted-text/types";
import styles from "./styles.module.scss";

type AppTextHighlighterProps = {
  text?: string | null;
  highlight?: string | null;
}

export const AppTextHighlighter = (props: AppTextHighlighterProps) => {
  const parts = getHighlightedText(props.text, props.highlight);

  return renderParts(parts);
};

const renderParts = (parts: HighlightedText[]) => {
  const res = parts.map((part, index) => {
    const className = part.isHighlighted ? styles.highlight : undefined;
    return (
      <span key={index} className={className}>{part.text}</span>
    );
  });

  return <span>{res}</span>;
};