import {getSearchPageLink, getURLSearchParameters} from "utils/search/helpers";
import {LinkPrevious} from "./link-previous/LinkPrevious";
import {FiltersForm} from "./filters-form/FiltersForm";
import styles from "./styles.module.scss";

export const AppContentFiltersList = () => {
  const getUrlToGoBack = () => {
    const {searchString, searchBy} = getURLSearchParameters();
    return getSearchPageLink({searchString, searchBy});
  };

  return (
    <div className={styles["filter-list-container"]}>
      <LinkPrevious to={getUrlToGoBack()} title="Назад к каталогу"/>
      <FiltersForm/>
    </div>
  );
};