import {AppInfoText} from "common/text/InfoText";
import {homePageRouteInfo} from "app/routing/home";
import {AppImage} from "common/image/Image";
import logo from "assets/samgmu-logo.png";
import {AppLink} from "common/link/Link";
import styles from "./styles.module.scss";

export const Logo = () => {
  return (
    <AppLink to={homePageRouteInfo.path!}
             className={styles.container}>
      <AppImage src={logo} className={styles.logo}
                previewClassName={styles.preview}
                alt="logo"/>
      <AppInfoText weight="light" className={styles.text}>
        Портал дистанционного образования
      </AppInfoText>
    </AppLink>
  );
};