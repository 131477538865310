import {Form} from "antd";
import {AppFormItemProps} from "./types";
import {getClassNames} from "utils/get-class-names/get-class-names";
import "./styles/styles.scss";

export const AppFormItem = (props: AppFormItemProps) => {
  const {paddingless, withoutExplain, ...rest} = props;
  const className = getItemClassName(paddingless, withoutExplain, rest.className);

  return <Form.Item {...rest} className={className}/>;
};

const getItemClassName = (paddingless?: boolean, withoutExplain?: boolean, className?: string) => {
  const wrapperClass = "app-form-item";
  const paddinglessStyles = paddingless ? "app-form-item--paddingless" : "";
  const withoutExplainStyles = withoutExplain ? "app-form-item--without-explain" : "";
  const base = `${wrapperClass} ${paddinglessStyles} ${withoutExplainStyles}`;

  return getClassNames(base, className);
};