import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getFullProfile} from "app/actions/profile/profile";
import {CubaUser} from "types/entities/CubaUser";
import {ProfileState} from "./types";

const initialState: ProfileState = {
  profile: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfile: (state: ProfileState) => {
      state.profile = null;
    }
  },
  extraReducers: {
    [getFullProfile.fulfilled.type]: (state: ProfileState, action: PayloadAction<CubaUser>) => {
      state.profile = action.payload;
    },
  }
});

export const profileSliceReducer = profileSlice.reducer;
export const {clearProfile} = profileSlice.actions;

