import React, {useEffect, useRef, useState} from "react";
import {AppImageProps} from "common/image/Image";

export const withLazyImage = (WrappedComponent: React.FunctionComponent<AppImageProps>) => {
  return (props: AppImageProps) => {
    const [src, setSrc] = useState<string>("");
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting && imageRef.current && imageRef.current.src !== props.src) {
          setSrc(props.src || "");
        }
      });

      if (imageRef.current) {
        observer.observe(imageRef.current);
      }

      return () => {
        if (imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    }, [imageRef.current, props.src]);

    return (
      <WrappedComponent {...props} src={src} imageRef={imageRef}/>
    );
  };
};