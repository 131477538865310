import {Select} from "antd";
import {SelectProps} from "antd/lib/select";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {EmptyDropdownMenu} from "./components/empty-dropdown-menu/EmptyDropdownMenu";
import "./styles/index.scss";

type ValueType = string | string[];

export const AppBaseSelect = <T extends ValueType = string>(props: SelectProps<T>) => {
  const className = getClassNames("app-select", props.className);

  return (
    <Select<T> notFoundContent={<EmptyDropdownMenu/>}
               {...props} className={className}/>
  );
};