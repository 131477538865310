import {AppFormItem} from "common/form/item/Base";
import {AppDateYearPicker} from "common/date-picker/DateYearPicker";
import {FormItemContainer} from "../../form-item-container/FormItemContainer";
import styles from "./styles.module.scss";

type FilterYearItemProps = {
  fieldName: string;
  title: string;
}

export const FilterYearItem = (props: FilterYearItemProps) => {
  return (
    <FormItemContainer title={props.title}>
      <AppFormItem name={props.fieldName} paddingless>
        <AppDateYearPicker className={styles.year} placeholder=""/>
      </AppFormItem>
    </FormItemContainer>
  );
};