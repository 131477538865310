import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSearch} from "app/hooks/search";
import {SEARCH_PAGE_PATH} from "app/routing/search/constants";
import {getURLSearchParameters} from "utils/search/helpers";
import {ESearchBy} from "types/enums";

export const useHeaderSearchPanelService = () => {
  const [searchString, setSearchString] = useState<string>("");
  const [searchBy, setSearchBy] = useState<string>(ESearchBy.ALL);
  const location = useLocation();
  const search = useSearch();

  useEffect(() => {
    if (location.pathname === SEARCH_PAGE_PATH) {
      const searchParameters = getURLSearchParameters();
      setSearchString(searchParameters.searchString ?? "");
      setSearchBy(checkSearchByValid(searchParameters.searchBy) ? searchParameters.searchBy : ESearchBy.ALL);
    }
  }, [location]);

  const onSearch = () => {
    const currentSearchParameters = getURLSearchParameters();
    const nextSearchParameters = {
      ...currentSearchParameters,
      searchString: searchString.trim(),
      searchBy,
    };
    search(nextSearchParameters);
  };

  return {
    state: {
      searchString,
      searchBy,
    },
    methods: {
      setSearchString,
      setSearchBy,
      onSearch,
    }
  };
};

const checkSearchByValid = (value?: string | null): value is ESearchBy => {
  return !!value && Object.values(ESearchBy).some(type => type === value);
};