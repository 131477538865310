import {Comments} from "app/components/comments/Comments";
import {ContentCardFull} from "app/components/resource/content-card-full/ContentCardFull";
import {HeaderSearchPanel} from "app/components/common/header-search-panel/HeaderSearchPanel";
import {ReserveModal} from "app/components/resource/reserve-modal/Reserve";
import {ContentMinCardList} from "app/components/search/content-min-list/ContentMinList";
import {AppContentTypesList} from "app/components/search/content-types-list/ContentTypesList";
import {AppLayout} from "common/layout/Layout";
import {AppSpacer} from "common/spacer/Spacer";
import {useResourcePage} from "./hook/use-resource";
import styles from "./styles.module.scss";

export const ResourcePage = () => {
  const {state, methods} = useResourcePage();

  return (
    <AppLayout documentTitle={state.content.name}>
      <HeaderSearchPanel/>
      <AppSpacer
        direction="horizontal"
        justifyContent="space-between"
        unit="20"
        className={styles.container}
      >
        <AppSpacer unit="80" className={styles.content}>
          <ContentCardFull
            element={state.content}
            openReserveModal={methods.show}
            error={state.error}
            loading={state.loading}
          />
          <Comments price={state.content.price}/>
          <ContentMinCardList
            cardClassName={styles.card}
            data={state.similarElements}
            linkClassName={styles.link}
            title="Похожие материалы"
            link={state.link}
          />
        </AppSpacer>
        <AppContentTypesList className={styles.types}/>
      </AppSpacer>
      <ReserveModal
        visible={state.visible}
        hide={methods.hide}
        element={state.content}
        profile={state.profile!}
      />
    </AppLayout>
  );
};
