import {ResourceElement, ResourceElementV2} from "app/reducers/search/types";

export const modifyResourceVersion = (
  resource: ResourceElementV2
): ResourceElement => {
  return {
    ...resource,
    image: {
      id: resource.image,
    },
    videoUrl: resource.promoVideoUrl,
    source: undefined,
    link: resource.externalLink ?? undefined,
  };
};
