import {basketPageInfo} from "app/routing/basket";
import {homePageRouteInfo} from "app/routing/home";
import {notFoundPageRouteInfo} from "app/routing/not-found";
import {purchasePageInfo} from "app/routing/purchases";
import {rejectPaymentPageRouteInfo} from "app/routing/reject-payment";
import {resourcePageRouteInfo} from "app/routing/resource";
import {searchPageRouteInfo} from "app/routing/search";
import {successPaymentPageRouteInfo} from "app/routing/success-payment";
// import {libraryPageRouteInfo} from "./library";

export const availableRoutes = [
  homePageRouteInfo,
  searchPageRouteInfo,
  resourcePageRouteInfo,
  /* Commented at 2023.04.06
				libraryPageRouteInfo,
		*/
  notFoundPageRouteInfo,
  basketPageInfo,
  successPaymentPageRouteInfo,
  rejectPaymentPageRouteInfo,
  purchasePageInfo,
];
