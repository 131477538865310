import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {Author} from "../author/Author";

import styles from "./styles.module.scss";

type CommentCardProps = {
  authorName: string;
  rating: number;
  description: string;
};

export const CommentCard = (props: CommentCardProps) => {
  const {authorName, rating, description} = props;
  return (
    <AppSpacer className={styles["comment-card"]} direction="horizontal">
      <Author name={authorName} rating={rating}/>
      <AppSpacer>
        <AppText>{description}</AppText>
      </AppSpacer>
    </AppSpacer>
  );
};
