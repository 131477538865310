import {MenuProps} from "antd/lib/menu";
import {Menu} from "antd";
import {getClassNames} from "utils/get-class-names/get-class-names";
import "./styles.scss";

export const AppMenuBase = (props: MenuProps) => {
  const {children, ...rest} = props;
  const className = getClassNames("app-menu", props.className);

  return (
    <Menu {...rest} className={className}>
      {children}
    </Menu>
  );
};