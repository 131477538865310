import {AppSelectOption} from "common/select/types";
import {ESearchBy} from "types/enums";

export const searchTypes: AppSelectOption[] = [
  {
    value: ESearchBy.ALL,
    text: "Искать везде",
  },
  {
    value: ESearchBy.DESCRIPTION,
    text: "Искать по описанию",
  },
  {
    value: ESearchBy.ATTACHED_DOCUMENTS,
    text: "Искать во вложенных документах",
  },
];