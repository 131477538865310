import {AppMenuItem} from "common/menu/menu-item/MenuItem";
import {DropdownAction} from "../../types";

export const renderTextMenuItem = (item: DropdownAction) => {
  return (
    <AppMenuItem key={item.title}
                 onClick={item.action}
                 disabled={item.disabled}>
      {item.title}
    </AppMenuItem>
  );
};
