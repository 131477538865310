import {Skeleton} from "antd";
import SkeletonImage from "antd/lib/skeleton/Image";
import SkeletonInput from "antd/lib/skeleton/Input";
import {AppCard} from "common/card/Card";
import {AppSpacer} from "common/spacer/Spacer";
import styles from "./styles.module.scss";

export const ContentCardFullSkeleton = () => {
    return (
      <AppCard className={styles.container}>
        <AppSpacer unit="40">
          <SkeletonInput/>
          <AppSpacer direction="horizontal" unit="40">
            <SkeletonImage className={styles.image}/>
            <Skeleton active paragraph={{rows: 8}}/>
          </AppSpacer>
        </AppSpacer>
      </AppCard>
    );
  };
