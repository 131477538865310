import {createAsyncThunk} from "@reduxjs/toolkit";
import {ContentType} from "app/reducers/content-types/types";
import {fetchAllContentTypes} from "rest/search/content-types";

export const getAllContentTypes = createAsyncThunk("FETCH_ALL_CONTENT_TYPES", async() => {
  try {
    const contentTypes: ContentType[] = await fetchAllContentTypes();
    return contentTypes || [];
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});