import {notification} from "antd";
import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";

export type CommentDTO = {
  id: string;
  contentId: string;
  comment: string;
  rating: number;
  userId?: string;
  visible: boolean;
};

export const addComment = (commentDto: CommentDTO): Promise<Comment> => {
  return appApi
    .post("/portal/review", commentDto)
    .then((res) => {
      notification.success({message: "Комментарий добавлен"});
      return res.data;
    })
    .catch(errorHandler("Не удалось добавить комментарий"));
};
