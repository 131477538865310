import styles from "./styles.module.scss";

type LoaderProps = {
  size?: "medium" | "small";
}

export const AppLoader = (props: LoaderProps) => {
  const {size = ""} = props;
  const className = `${styles.loader} ${styles[size]}`;
  return (
    <div className={className}/>
  );
};