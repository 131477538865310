import {TSearchParameters} from "types/search-params";

export const parseUrl = (url?: string | null): TSearchParameters => {
  if (!url) {
    return {};
  }

  try {
    const {searchParams} = new URL(url);

    const params: TSearchParameters = Array.from(searchParams.entries()).reduce((acc, item: [string, string]) => {
      return {
        ...acc,
        [item[0]]: item[1],
      };
    }, {} as TSearchParameters);

    return params;
  } catch {
    return {};
  }
};