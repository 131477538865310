import {AppFormItem} from "common/form/item/Base";
import {FormItemContainer} from "../../form-item-container/FormItemContainer";
import styles from "./styles.module.scss";
import {AppDatePicker} from "common/date-picker/DatePicker";

type FilterDateItemProps = {
  fieldName: string;
  title: string;
}

export const FilterDateItem = (props: FilterDateItemProps) => {
  return (
    <FormItemContainer title={props.title}>
      <AppFormItem name={props.fieldName} paddingless>
        <AppDatePicker className={styles.date} placeholder=""/>
      </AppFormItem>
    </FormItemContainer>
  );
};