import {AppSpacer} from "common/spacer/Spacer";
import {AppPrimaryButton} from "common/button/primary/Primary";
import {SearchContainer} from "./components/search-container/SearchContainer";
import {useHeaderSearchPanelService} from "./hooks/service";
import {searchTypes} from "./search-types";
import styles from "./styles.module.scss";

export const HeaderSearchPanel = () => {
  const {state, methods} = useHeaderSearchPanelService();

  return (
    <AppSpacer direction="horizontal" unit="12"
               className={styles.container}>
      <SearchContainer inputValue={state.searchString}
                       onInputChange={methods.setSearchString}
                       selectValue={state.searchBy}
                       onSelectChange={methods.setSearchBy}
                       selectOptions={searchTypes}
                       onSearch={methods.onSearch}/>
      <AppPrimaryButton className={styles["search-button"]}
                        onClick={methods.onSearch}>
        Поиск
      </AppPrimaryButton>
    </AppSpacer>
  );
};