import {getClassNames} from "utils/get-class-names/get-class-names";
import {roundRating} from "utils/numbers/roundRating";
import {replaceDot} from "./utils/replaceDot";
import {AppRate} from "common/rate/Rate";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {useState} from "react";

import styles from "./styles.module.scss";

type RatingProps = {
  rating: number;
  disabled?: boolean;
  reverse?: boolean;
  className?: string;
};

export const Rating = (props: RatingProps) => {
  const [rate, setRate] = useState<number>(roundRating(props.rating));

  const containerClassName = getClassNames(
    props.reverse && styles.reverse,
    props.className || ""
  );
  const rateClassName = getClassNames(
    styles.rate,
    props.reverse && styles["rate-reverse"]
  );

  const onChange = (value: number) => {
    setRate(value);
  };

  return (
    <AppSpacer
      direction="horizontal"
      alignItems="center"
      className={containerClassName}
    >
      <AppRate
        className={rateClassName}
        allowHalf
        onChange={onChange}
        value={rate}
        disabled={props.disabled}
      />
      <AppText className={styles["rate-count"]}>{replaceDot(rate)}</AppText>
    </AppSpacer>
  );
};
