import React from "react";
import {USER_YEAR_FORMAT} from "utils/date/date";
import {AppDatePicker, AppDatePickerProps, DatePickerComponent} from "./DatePicker";

export const AppDateYearPicker = React.forwardRef<DatePickerComponent, AppDatePickerProps>(
  (props, ref) => {
    return (
      <AppDatePicker picker="year"
                     format={USER_YEAR_FORMAT}
                     {...props} ref={ref}/>
    );
  }
);