import {useEffect, useState} from "react";
import transparent from "assets/transparent.png";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {ImgProps} from "types/props";
import {withLazyImage} from "app/hocs/with-lazy/with-lazy-image";
import styles from "./styles.module.scss";

export type AppImageProps = Omit<ImgProps, "src" | "alt"> & {
  src: string;
  alt: string;
  previewClassName?: string;
  imageRef?: React.RefObject<HTMLImageElement>;
};

export const AppImage = (props: AppImageProps) => {
  const {className, previewClassName, imageRef, ...rest} = props;
  const [src, setSrc] = useState<string>(props.src);
  const [error, setError] = useState<boolean>(false);
  const classNames = error
    ? getClassNames(styles.image, className, styles.preview, previewClassName)
    : getClassNames(styles.image, className);

  useEffect(() => {
    setSrc(props.src);
    setError(false);
  }, [props.src]);

  const onError = () => {
    setSrc(transparent);
    setError(true);
  };

  return (
    <img
      {...rest}
      draggable={false}
      src={src}
      className={classNames}
      onError={onError}
      ref={imageRef}
    />
  );
};

export const AppLazyImage = withLazyImage(AppImage);
