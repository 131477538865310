import {getBasket} from "app/actions/basket/basket";
import {getPurchases} from "app/actions/purchases/purchases";
import {getAllContentTypes} from "app/actions/search/content-types";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect, useState} from "react";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useKeycloak} from "@react-keycloak/web";
import {clearProfile} from "app/reducers/profile";
import {getFullProfile} from "app/actions/profile/profile";
import {KeycloakProfile} from "app/reducers/profile/types";

export const useAuth = () => {
  const {keycloak} = useKeycloak();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const {profileState} = useAppSelector((state) => state);

  const resetProfile = () => {
    dispatch(clearProfile());
  };

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      Promise.all([
        dispatch(getAllContentTypes()),
        dispatch(getFullProfile(keycloak.idTokenParsed as KeycloakProfile)),
      ]).finally(() => setLoading(false));
    } else {
      dispatch(getAllContentTypes()).finally(() => setLoading(false));
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    keycloak.onAuthRefreshError = resetProfile;
    keycloak.onAuthLogout = resetProfile;
  }, []);

  useEffect(() => {
    if (
      keycloak &&
      keycloak.authenticated &&
      profileState &&
      profileState.profile?.id
    ) {
      const basketAction = getBasket(profileState.profile.id);
      const purchasesAction = getPurchases(profileState.profile.id);

      dispatch(basketAction);
      dispatch(purchasesAction);
    }
  }, [profileState, keycloak]);

  return {
    loading,
  };
};
