import {Pagination, PaginationProps} from "antd";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {showTotal} from "./utils/show-total";
import styles from "./styles.module.scss";

export const AppPagination = (props: PaginationProps) => {
  const className = getClassNames(styles.pagination, props.className);

  return <Pagination showTotal={showTotal}
                     {...props} className={className}/>;
};