import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getAllBypassSheetsForUser, requestBypassSheet} from "app/actions/bypass-sheet/bypass-sheet";
import {BypassSheet, BypassSheetServerResponse} from "rest/bypass-sheets/types";
import {BypassSheetsState} from "./types";

const initialState: BypassSheetsState = {
  elements: [],
  isCreateAvailable: false,
  loading: true,
};

const bypassSheetsSlice = createSlice({
  name: "bypassSheets",
  initialState,
  reducers: {
    clearElements: (state: BypassSheetsState) => {
      state.elements = [];
      state.loading = true;
      state.isCreateAvailable = false;
    },
  },
  extraReducers: {
    [requestBypassSheet.fulfilled.type]: (state: BypassSheetsState, action: PayloadAction<BypassSheetServerResponse>) => {
      state.elements = action.payload.elements.sort(sortByNumber);
      state.isCreateAvailable = action.payload.isCreateAvailable;
    },
    [getAllBypassSheetsForUser.pending.type]: (state: BypassSheetsState) => {
      state.loading = true;
    },
    [getAllBypassSheetsForUser.fulfilled.type]: (state: BypassSheetsState, action: PayloadAction<BypassSheetServerResponse>) => {
      state.elements = action.payload.elements.sort(sortByNumber);
      state.isCreateAvailable = action.payload.isCreateAvailable;
      state.loading = false;
    },
    [getAllBypassSheetsForUser.rejected.type]: (state: BypassSheetsState) => {
      state.loading = false;
    },
  }
});

export const bypassSheetsReducer = bypassSheetsSlice.reducer;
export const {clearElements} = bypassSheetsSlice.actions;

export function sortByNumber(first: BypassSheet, second: BypassSheet) {
  return first.number <= second.number ? 1 : -1;
}
