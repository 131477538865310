import axios, {CancelTokenSource} from "axios";
import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";
import {StatementRequestDTO} from "types/entities/StatementRequestDTO";

export type StatementRequestsListParams = {
  id: string | null;
  onlyPersonal: boolean;
  limit: number;
  offset: number;
}

const _getStatementRequestsList = () => {
  let source: CancelTokenSource | undefined;

  return (params: StatementRequestsListParams): Promise<StatementRequestDTO[]> => {
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();

    return appApi
      .post(
        "v2/services/educationalportal_RestExcerptOrderService/getOrderList",
        {userId: params.id, onlyPersonal: params.onlyPersonal},
        {cancelToken: source.token}
      )
      .then(res => res.data)
      .catch(errorHandler("Не удалось загрузить запросы тематических выписок"));
  };
};

export const getStatementRequestsList = _getStatementRequestsList();