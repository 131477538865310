import {AppFormItem} from "common/form/item/Base";
import {AppCheckbox} from "common/checkbox/Checkbox";
import {FormItemContainer} from "../../form-item-container/FormItemContainer";
import styles from "./styles.module.scss";

type FilterCheckboxItemProps = {
  fieldName: string;
  title: string;
}

export const FilterCheckboxItem = (props: FilterCheckboxItemProps) => {
  return (
    <FormItemContainer title={props.title}>
      <AppFormItem name={props.fieldName} valuePropName="checked" paddingless>
        <AppCheckbox className={styles.checkbox}/>
      </AppFormItem>
    </FormItemContainer>
  );
};