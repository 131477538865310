import {CubaUser} from "types/entities/CubaUser";

type User = Pick<CubaUser, "lastName" | "firstName" | "middleName">

export const getUserFullName = (user?: User | null) => {
  if (!user) {
    return "";
  }
  const {firstName = "", middleName = "", lastName = ""} = getFullNameParts(user);
  return `${lastName} ${firstName} ${middleName}`;
};

export const getUserShortFullName = (user?: User | null) => {
  if (!user) {
    return "";
  }
  const {firstName, middleName, lastName} = getFullNameParts(user);

  const nameFirstLetter = firstName[0] ? firstName[0].toUpperCase() : "";
  const nameAbbr = nameFirstLetter ? ` ${nameFirstLetter}.` : "";

  const middleNameFirstLetter = middleName[0] ? middleName[0].toUpperCase() : "";
  const middleNameAbbr = middleNameFirstLetter ? ` ${middleNameFirstLetter}.` : "";

  return `${lastName}${nameAbbr}${middleNameAbbr}`;
};


const getFullNameParts = (user: User) => {
  return {
    firstName: user && user.firstName ? user.firstName : "",
    middleName: user && user.middleName ? user.middleName : "",
    lastName: user && user.lastName ? user.lastName : "",
  };
};