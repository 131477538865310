import {PaymentPageContent} from "app/components/payment/PaymentPageContent";
import {AppLayout} from "common/layout/Layout";

export const SuccessPaymentPage = () => {
  return (
    <AppLayout>
      <PaymentPageContent success={true}/>
    </AppLayout>
  );
};
