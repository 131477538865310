import {AppPagination} from "common/pagination/AppPagination";
import {useSearchByTypePagination} from "./hook/service";
import styles from "./styles.module.scss";

export const SearchByTypePagination = () => {
  const {state, methods} = useSearchByTypePagination();

  return (
    <>
      {
        state.shouldRender && (
          <AppPagination current={state.currentPage}
                         onChange={methods.onChange}
                         hideOnSinglePage
                         pageSize={state.pageSize}
                         total={state.total}
                         className={styles.pagination}/>
        )
      }
    </>
  );
};