import {AppForm} from "common/form/Form";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {AppPrimaryButton} from "common/button/primary/Primary";
import {AppLoaderPlaceholder} from "common/loader-placeholder/LoaderPlacehlder";
import {Filters} from "./components/filters/Filters";
import {useFiltersFormService} from "./hooks/service";
import styles from "./styles.module.scss";

export const FiltersForm = () => {
  const {state, methods} = useFiltersFormService();

  return (
    <AppForm form={state.form} onSubmit={methods.onSubmit}>
      <AppSpacer direction="vertical" unit="0" className={styles.container}>
        {
          state.loading ? (
            <AppLoaderPlaceholder className={styles.loader}/>
          ) : (
            <>
              <AppText className={styles.header}>
                {state.contentType?.pluralName ?? ""}
              </AppText>
              <AppSpacer direction="vertical" unit="10" className={styles.body}>
                <Filters filters={state.filters}/>
                <AppPrimaryButton onClick={methods.onSubmit} className={styles.submit}>
                  Применить фильтр
                </AppPrimaryButton>
              </AppSpacer>
            </>
          )
        }
      </AppSpacer>
    </AppForm>
  );
};