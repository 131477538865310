import React from "react";
import styles from "./styles.module.scss";

export type AppInputPostfixProps = {
  postfix?: React.ReactNode;
  disabled?: boolean;
};

export const AppInputPostfix = (props: AppInputPostfixProps) => {
  const disabledStyles = props.disabled ? styles["postfix--disabled"] : "";
  const className = `${styles.postfix} ${disabledStyles}`;

  return props.postfix ? (
    <div className={className}>
      {props.postfix}
    </div>
  ) : null;
};