import {DEFAULT_LIMIT, SearchContentParams} from "rest/search/search-content";
import {TSearchParameters} from "types/search-params";

export const generateSearchContentParams = (searchParameters: TSearchParameters): SearchContentParams => {
  const params: SearchContentParams = {
    ...searchParameters,
    limit: searchParameters.contentTypeId ? Number(searchParameters.limit) || DEFAULT_LIMIT : null,
    offset: searchParameters.contentTypeId ? Number(searchParameters.offset) || 0 : null,
  };

  return deleteUnusedFields(params);
};

const deleteUnusedFields = (params: SearchContentParams) => {
  const result = {...params};

  (Object.keys(result) as ((keyof SearchContentParams)[])).forEach(key => {
    if (result[key] === undefined || result[key] === null) {
      delete result[key];
    }
  });

  return result;
};