import {createAsyncThunk} from "@reduxjs/toolkit";
import {getUserPurchases} from "rest/purchase/getUserPurchases";

export const getPurchases = createAsyncThunk(
  "purchases/getPurchases",
  async (userId: string) => {
    try {
      return getUserPurchases(userId);
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);
