import {SEARCH_PAGE_PATH} from "app/routing/search/constants";
import {TSearchParameters} from "types/search-params";
import {buildURL} from "./url-builder/url-builder";
import {parseUrl} from "./url-parser/url-parser";

export const getSearchPageLink = (searchParams: TSearchParameters) => {
  return `${SEARCH_PAGE_PATH}${buildURL(searchParams).search}`;
};

export const getURLSearchParameters = () => {
  return parseUrl(window.location.href);
};

export const getURL = () => {
  return new URL(window.location.href);
};