import {AppTextButton} from "common/button/text/Text";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import styles from "./styles.module.scss";

type ToogleButtonProps = {
  onClick(): void;
  visible: boolean;
}

export const ToggleButton = (props: ToogleButtonProps) => {
  const buttonClassName = `${styles.button} ${props.visible ? styles.opened : styles.closed}`;

  return (
    <AppTextButton onClick={props.onClick}
                   className={buttonClassName}>
      {
        props.visible ? (
          <span>Скрыть категории <UpOutlined className={styles.icon}/></span>
        ) : (
          <span>Раскрыть категории <DownOutlined className={styles.icon}/></span>
        )
      }
    </AppTextButton>

  );
};