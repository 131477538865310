import {Rating} from "app/components/common/rating/Rating";
import {AppAvatar} from "common/avatar/Avatar";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";

import styles from "app/components/comments/comments-panel/author/styles.module.scss";

type AuthorProps = {
  name: string;
  rating: number;
};

export const Author = (props: AuthorProps) => {
  const {name, rating} = props;
  return (
    <AppSpacer
      direction="horizontal"
      className={styles.author}
      alignItems="flex-start"
    >
      <AppSpacer direction="horizontal" alignItems="center">
        <AppAvatar/>
        <AppSpacer>
          <AppText className={styles.name} size={16}>
            {name}
          </AppText>
          <Rating rating={rating} disabled/>
        </AppSpacer>
      </AppSpacer>
    </AppSpacer>
  );
};
