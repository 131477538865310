import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {SearchResponse} from "app/reducers/search/types";
import {searchContentActions} from "app/reducers/search";
import {TSearchParameters} from "types/search-params";
import {searchContent, SearchContentParams} from "rest/search/search-content";
import {modifyResourceVersion} from "utils/resource/modifyResourceVersion";
import {generateSearchContentParams} from "./generate-search-content-params";

type RequestOptions = {
  searchParameters: TSearchParameters;
  userId?: string;
};

export const findContent = createAsyncThunk<SearchResponse, RequestOptions>(
  "SEARCH_CONTENT",
  async ({searchParameters, userId}, {dispatch}) => {
    try {
      const params: SearchContentParams = generateSearchContentParams(
        searchParameters
      );

      const response = await searchContent(params, userId);

      return {
        ...response,
        elements: response.elements.map(modifyResourceVersion),
      } as SearchResponse;
    } catch (e: unknown) {
      if (!axios.isCancel(e)) {
        dispatch(searchContentActions.rejectedByError());
      }
      throw new Error(e as string);
    }
  }
);
