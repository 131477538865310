import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createComment, getComments} from "app/actions/comments/comments";
import {Comment, CommentsState} from "app/reducers/comments/types";
import {CommentsResponse} from "rest/comments/types";

const initialState: CommentsState = {
  comments: [],
  loading: true,
  error: "",
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    [getComments.pending.type]: () => initialState,
    [getComments.rejected.type]: (state: CommentsState) => {
      state.error = "При загрузке комментариев произошла ошибка";
      state.loading = false;
    },
    [getComments.fulfilled.type]: (
      state: CommentsState,
      action: PayloadAction<CommentsResponse>
    ) => {
      state.comments = action.payload;
      state.error = "";
      state.loading = false;
    },
    [createComment.fulfilled.type]: (
      state: CommentsState,
      action: PayloadAction<Comment>
    ) => {
      state.comments.push(action.payload);
      state.error = "";
      state.loading = false;
    },
  },
});

export const {
  reducer: commentsReducer,
  actions: commentsActions,
} = commentsSlice;
