import {createAsyncThunk} from "@reduxjs/toolkit";
import {addToBasket} from "rest/basket/addToBasket";
import {getBasketContent} from "rest/basket/getBasket";
import {removeFromBasket} from "rest/basket/removeFromBasket";
import {
  AddToBasketRequestParams,
  UpdateBasketItemParams,
} from "rest/basket/types";
import {updateBasketItem} from "rest/basket/updateBasketItem";

export const getBasket = createAsyncThunk(
  "basketContent/getBasketContent",
  async (userId: string) => {
    try {
      return getBasketContent(userId);
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);

export const removeBasketItem = createAsyncThunk(
  "basketContent/removeBasketItem",
  async (basketContentId: string) => {
    try {
      return removeFromBasket(basketContentId);
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);

export const addBasketItem = createAsyncThunk(
  "basketContent/addBasketItem",
  async (basketItem: AddToBasketRequestParams) => {
    try {
      return addToBasket(basketItem);
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);

export const changeBasketItem = createAsyncThunk(
  "basketContent/updateBasketItem",
  async (basketItem: UpdateBasketItemParams) => {
    try {
      return updateBasketItem(basketItem);
    } catch (error: unknown) {
      throw new Error(error as string);
    }
  }
);
