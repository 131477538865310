import {AddToBasket} from "app/components/common/add-to-basket/AddToBasket";
import {ChangeAmount} from "app/components/common/change-amount/ChangeAmount";
import {Rating} from "app/components/common/rating/Rating";
import {RESOURCE_PAGE_PATH} from "app/routing/resource/constants";
import {AppCheckbox} from "common/checkbox/Checkbox";
import {AppLink} from "common/link/Link";
import React from "react";
import {ResourceElement} from "app/reducers/search/types";
import {AppCard} from "common/card/Card";
import {AppSpacer} from "common/spacer/Spacer";
import {AppInfoText} from "common/text/InfoText";
import {AppText} from "common/text/Text";
import {AppTitle} from "common/title/Title";
import {getDownloadUrl} from "utils/get-download-url/get-download-url";
import {getFullYear} from "utils/date/get-full-year/get-full-year";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {HighlightProps} from "types/props";
import {EHighlightPart} from "types/enums";
import {AppLazyImage} from "common/image/Image";
import {renderHighlightedNode} from "utils/highlight/render-highlighted-node/render-highlighted-node";
import {parseHTML} from "utils/html-parser/html-parser";
import {replacePathParams} from "utils/replace-path-params/replace-path-params";
import previewStyles from "../styles.module.scss";
import styles from "./styles.module.scss";

type ContentCardProps = HighlightProps & {
  element: ResourceElement;
  className?: string;
  showContentType?: boolean;
  showDescription?: boolean;
  isBasketItem?: boolean;
  isPurchaseItem?: boolean;
  onClickCheckBox?: (contentId: string) => void;
  selected?: boolean;
  hideRating?: boolean;
  quantity?: number;
  purchasePrice?: number;
};

export const ContentCard = (props: ContentCardProps) => {
  const {
    element,
    className,
    highlight,
    showContentType = true,
    showDescription = true,
    isBasketItem = false,
    onClickCheckBox,
    selected,
    hideRating,
    quantity,
    purchasePrice,
    isPurchaseItem,
  } = props;
  const {
    image,
    name = "",
    source,
    publishDate,
    contentType,
    description,
    price,
    averageRating,
    multiple,
  } = element;
  const classNames = getClassNames(styles.card, className);
  const date = `${getFullYear(publishDate) ?? ""}`;

  const renderHighlighted = (
    currentPart: EHighlightPart,
    node: React.ReactNode
  ) => {
    return renderHighlightedNode({
      partsToHighlight: highlight?.highlightParts,
      currentPart,
      node,
      pattern: highlight?.pattern,
    });
  };

  const handleClickCheckbox = () => {
    if (onClickCheckBox) {
      onClickCheckBox(element.id);
    }
  };

  const getPriceValue = () => {
    if (purchasePrice && quantity && isPurchaseItem) {
      const totalPrice = multiple ? purchasePrice * quantity : purchasePrice;
      return totalPrice + " ₽";
    }
    if (price) {
      return price + " ₽";
    }
  };

  const showPriceBlock =
    !!price || isBasketItem || (!!purchasePrice && !!quantity);
  const showPriceText = price || !!purchasePrice;

  return (
    <AppCard className={classNames}>
      <AppSpacer direction="horizontal" alignItems="flex-start">
        {isBasketItem && (
          <AppCheckbox checked={selected} onClick={handleClickCheckbox}/>
        )}
        <AppSpacer direction="horizontal" unit="40" className={styles.main}>
          <AppLink
            to={replacePathParams(RESOURCE_PAGE_PATH, [element.id])}
            key={element.id}
            className={styles.link}
          >
            <AppSpacer direction="horizontal">
              <AppLazyImage
                previewClassName={
                  previewStyles[`preview-${contentType?.materialType}`]
                }
                className={styles.image}
                src={getDownloadUrl(image)}
                alt={name}
              />
              <AppSpacer unit="12" className={styles["description-container"]}>
                <AppSpacer
                  unit="10"
                  direction="horizontal"
                  alignItems="flex-start"
                >
                  <AppSpacer
                    className={styles["info-container"]}
                    direction="horizontal"
                  >
                    <AppTitle level={2} className={styles.title}>
                      {renderHighlighted(EHighlightPart.NAME, name)}
                      {name && date ? ", " : ""}
                      {date && (
                        <AppText className={styles.date}>
                          {renderHighlighted(EHighlightPart.PUBLISH_DATE, date)}
                          {date ? "г." : ""}
                        </AppText>
                      )}
                    </AppTitle>
                  </AppSpacer>
                  <div
                    className={styles.rating}
                    onClick={(e) => e.preventDefault()}
                  >
                    {averageRating && !hideRating && (
                      <Rating rating={averageRating} reverse disabled/>
                    )}
                  </div>
                </AppSpacer>
                {showContentType && (
                  <AppText className={styles["content-type"]}>
                    {contentType?.name ?? ""}
                  </AppText>
                )}
                {showDescription && description && (
                  <AppText className={styles.description}>
                    {renderHighlighted(
                      EHighlightPart.DESCRIPTION,
                      parseHTML(description)
                    )}
                  </AppText>
                )}
                {source?.name && (
                  <AppSpacer unit="8" direction="horizontal">
                    <AppInfoText className={styles.source}>
                      Источник:
                    </AppInfoText>
                    <AppInfoText className={styles.source}>
                      {renderHighlighted(
                        EHighlightPart.SOURCE_NAME,
                        source?.name
                      )}
                    </AppInfoText>
                  </AppSpacer>
                )}
              </AppSpacer>
            </AppSpacer>
          </AppLink>
          {showPriceBlock && (
            <AppSpacer
              justifyContent="flex-start"
              className={styles["price-container"]}
            >
              <AppSpacer justifyContent="space-between" alignItems="flex-end">
                {showPriceText && (
                  <AppText className={styles["price-text"]}>
                    {getPriceValue()}
                  </AppText>
                )}
                {isBasketItem && element.multiple && (
                  <ChangeAmount contentId={element.id}/>
                )}
                <div
                  onClick={(e) => e.preventDefault()}
                  className={styles["buy-button"]}
                >
                  <AddToBasket
                    resourceElement={element}
                    showTooltip
                    isBasketItem={isBasketItem}
                  />
                </div>
                {quantity && multiple && (
                  <AppTitle level={2}>{quantity} ед.</AppTitle>
                )}
              </AppSpacer>
            </AppSpacer>
          )}
        </AppSpacer>
      </AppSpacer>
    </AppCard>
  );
};
