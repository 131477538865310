import moment from "moment";
import {EFilterType} from "types/enums";
import {FilterDto, FiltersForm} from "../types";

export const getInitialFormValues = (filters: FilterDto[]) => {
  const {searchParams} = new URL(window.location.href);
  return filters.reduce((acc, filter) => {
    if (searchParams.has(filter.id)) {
      if (filter.type === EFilterType.DATE) {
        return {
          ...acc,
          [filter.id]: moment(searchParams.get(filter.id))
        };
      }
      if (filter.fullPathToProperty === "publishDate") {
        return {
          ...acc,
          [filter.id]: moment().set("year", parseInt(searchParams.get(filter.id)!))
        };
      }
      return {
        ...acc,
        [filter.id]: searchParams.get(filter.id)
      };
    }
    return acc;
  }, {} as FiltersForm);
};
