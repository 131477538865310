import {KeyboardEventHandler} from "react";
import {Form} from "antd";
import {FormProps} from "antd/lib/form/Form";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {formValidateMessages} from "./validate-messages";
import styles from "./styles.module.scss";

type AppFormProps = FormProps &
  {
    onSubmit?(): void;
  }

export const AppForm = (props: AppFormProps) => {
  const className = getClassNames(styles.form, props.className);

  const onKeyPress: KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e.key === "Enter") {
      props.onSubmit?.();
    }
  };

  return (
    <Form layout="vertical" onKeyPress={onKeyPress} {...props} className={className}
          validateMessages={formValidateMessages}>
      {props.children}
    </Form>
  );
};
