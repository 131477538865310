import {FormInstance} from "antd/lib/form";
import {ResourceElement} from "app/reducers/search/types";
import moment, {Moment} from "moment";
import {reserveFormFields} from "../fields";

export const startDateValidator = (form: FormInstance, element: ResourceElement) => {
  return (_: any, currentValue: Moment) => {
    if (!currentValue) {
      return Promise.reject("Обязательное поле");
    }

    const endDateFormValue = moment(form.getFieldValue(reserveFormFields.reserveEnds));

    if (element.availableReserveStarts && currentValue < moment(element.availableReserveStarts)) {
      return Promise.reject("Выбранная дата не входит в допустимый диапазон");
    }

    if (element.availableReserveEnds && currentValue > moment(element.availableReserveEnds)) {
      return Promise.reject("Выбранная дата не входит в допустимый диапазон");
    }

    if (!endDateFormValue) {
      return Promise.resolve();
    }

    if (currentValue > endDateFormValue) {
      return Promise.reject("Дата начала больше даты окончания");
    }

    return Promise.resolve();
  };
};