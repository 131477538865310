import {HighlightedText} from "./types";

export const getHighlightedText = (text?: string | null, highlight?: string | null): HighlightedText[] => {
  if (!text?.trim()) {
    return [];
  }

  if (!highlight?.trim()) {
    return [{text, isHighlighted: false}];
  }

  const reg = new RegExp(`(${escapeRegExp(highlight.trim())})`, "i");
  const parts = text.trim().split(reg);
  return parts
    .filter(part => part)
    .map(part => ({text: part, isHighlighted: reg.test(part)}));
};

const escapeRegExp = (str = "") => (
  str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
);
