import {AppFormItem} from "common/form/item/Base";
import {AppSelect} from "common/select/single-options/SingleSelect";
import {AppSelectOption} from "common/select/types";
import styles from "./styles.module.scss";

type FilterSelectItemProps = {
  fieldName: string;
  options: AppSelectOption[],
  placeholder?: string;
}

export const FilterSelectItem = (props: FilterSelectItemProps) => {
  return (
    <AppFormItem name={props.fieldName} paddingless>
      <AppSelect className={styles.select}
                 options={props.options}
                 placeholder={props.placeholder}
                 showSearch={false}/>
    </AppFormItem>
  );
};