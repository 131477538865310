import Button, {ButtonProps} from "antd/lib/button/button";
import {getClassNames} from "utils/get-class-names/get-class-names";

import styles from "./styles.module.scss";

export const IconButton = (props: ButtonProps) => {
  const {className} = props;

  const classNames = getClassNames(styles["icon-button"], className);

  return <Button {...props} className={classNames}/>;
};
