import {AppPrimaryButton} from "common/button/primary/Primary";
import {AppModal} from "common/modal/Modal";
import {AppRate} from "common/rate/Rate";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {AppTextArea} from "common/textarea/TextArea";
import {ChangeEvent} from "react";

import styles from "./styles.module.scss";

type AddCommentModalProps = {
  open: boolean;
  closeModal: () => void;
  createComment: () => void;
  onChangeText: (value: string) => void;
  onChangeRating: (value: number) => void;
};

export const AddCommentModal = (props: AddCommentModalProps) => {
  const {
    open,
    closeModal,
    createComment,
    onChangeText,
    onChangeRating,
  } = props;
  const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeText(event.target.value);
  };

  return (
    <AppModal
      className={styles.modal}
      visible={open}
      title={"Написать отзыв"}
      onCancel={closeModal}
    >
      <AppSpacer unit="30">
        <AppSpacer direction="horizontal" alignItems="center" unit="60">
          <AppText size={14}>Ваша оценка</AppText>
          <AppRate
            className={styles.rate}
            allowHalf
            onChange={onChangeRating}
          />
        </AppSpacer>
        <AppSpacer direction="horizontal" unit="60">
          <AppText size={14}>Текст отзыва</AppText>
          <AppSpacer className={styles["area-container"]}>
            <AppTextArea
              className={styles.textarea}
              onChange={onChangeTextArea}
            />
            <AppPrimaryButton className={styles.submit} onClick={createComment}>
              Добавить отзыв
            </AppPrimaryButton>
          </AppSpacer>
        </AppSpacer>
      </AppSpacer>
    </AppModal>
  );
};
