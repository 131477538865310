import {EHighlightPart, ESearchBy} from "types/enums";

export const getHighlightParts = (searchBy?: string | null) => {
  switch (searchBy) {
    case ESearchBy.DESCRIPTION: {
      return [EHighlightPart.DESCRIPTION];
    }
    case ESearchBy.ALL:
    case ESearchBy.ATTACHED_DOCUMENTS: {
      return Object.values(EHighlightPart);
    }
    default: {
      return [];
    }
  }
};