import {createAsyncThunk} from "@reduxjs/toolkit";
import {KeycloakProfile, ProfileRequestBody} from "app/reducers/profile/types";
import {CubaUser} from "types/entities/CubaUser";
import {findOrRegisterUser} from "rest/auth/profile";

export const getFullProfile = createAsyncThunk("GET_FULL_PROFILE", async(keycloakProfile: KeycloakProfile) => {
  try {
    const minProfile: ProfileRequestBody = {
      id: keycloakProfile.sub || "",
      login: keycloakProfile.preferred_username || "",
      firstName: keycloakProfile.given_name || "",
      lastName: keycloakProfile.family_name || "",
      middleName: keycloakProfile.middle_name || "",
      email: keycloakProfile.email || "",
    };
    const fullProfile: CubaUser = await findOrRegisterUser(minProfile);
    return fullProfile;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});