import {createAsyncThunk} from "@reduxjs/toolkit";
import {ExhibitionItem} from "app/reducers/exhibitions/types";
import {getPublishedVirtualExhibitions} from "rest/exhibition/get-all";
import {getVirtualExhibitionById} from "rest/exhibition/get-by-id";

export const fetchPublishedVirtualExhibition = createAsyncThunk("FETCH_VIRTUAL_EXHIBITIONS", async() => {
  try {
    const data: ExhibitionItem[] = await getPublishedVirtualExhibitions();
    return data;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const fetchVirtualExhibitionById = createAsyncThunk("FETCH_VIRTUAL_EXHIBITION_BY_ID", async(contentId: string) => {
  try {
    const data: ExhibitionItem = await getVirtualExhibitionById(contentId);
    return data;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});