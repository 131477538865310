import {ButtonProps} from "antd/lib/button/button";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {AppButton} from "../Button";
import styles from "./styles.module.scss";

export const AppPrimaryButton = (props: ButtonProps) => {
  const {className, ...rest} = props;
  const classNames = getClassNames(styles["btn-primary"], className);

  return (
    <AppButton type="primary" className={classNames} {...rest}/>
  );
};