import {CubaUser} from "types/entities/CubaUser";
import {ResourceElement} from "app/reducers/search/types";
import {AppModal} from "common/modal/Modal";
import {AppSpacer} from "common/spacer/Spacer";
import {useReserveModal} from "./hook";
import {ReserveForm} from "app/components/resource/reserve-form/ReserveForm";
import {ReserveContentInfo} from "./info/ContentInfo";
import {ReserveModalButtons} from "./buttons/ReserveButtons";
import styles from "./styles.module.scss";

export type ReserveModalProps = {
  visible: boolean;
  hide(): void;
  element: ResourceElement;
  profile: CubaUser;
}

export const ReserveModal = (props: ReserveModalProps) => {
  const {state, methods} = useReserveModal(props);

  return (
    <AppModal title="Резервирование издания" closable={!state.loading}
              maskClosable={!state.loading} visible={props.visible}
              onCancel={props.hide}>
      <AppSpacer unit="20" className={styles.container}>
        <ReserveContentInfo profile={props.profile} element={props.element}/>
        <ReserveForm loading={state.loading} form={state.form} element={props.element}/>
        <ReserveModalButtons loading={state.loading} applyForReserve={methods.applyForReserve} hide={props.hide}/>
      </AppSpacer>
    </AppModal>
  );
};