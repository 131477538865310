import {createAsyncThunk} from "@reduxjs/toolkit";
import {NewAndPopularResponseV2} from "app/reducers/new-and-popular/types";
import {searchNewAndPopularContent} from "rest/search/search-new-and-popular";
import {modifyResourceVersion} from "utils/resource/modifyResourceVersion";

export const getNewAndPopularContents = createAsyncThunk(
  "FETCH_NEW_AND_POPULAR",
  async (userId?: string) => {
    try {
      const data: NewAndPopularResponseV2 = await searchNewAndPopularContent(
        userId
      );
      return {
        newContents: data.newContents.map((item) =>
          modifyResourceVersion(item)
        ),
        popularContents: data.popularContents.map((item) =>
          modifyResourceVersion(item)
        ),
      };
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
