import {createAsyncThunk} from "@reduxjs/toolkit";
import {notification} from "antd";
import {ProfileState} from "app/reducers/profile/types";
import {StatementRequestsState} from "app/reducers/statement-request/types";
import {_createStatementRequest} from "rest/statement-requests/create";
import {StatementRequestDTO} from "types/entities/StatementRequestDTO";

type StateSlice = {
  statementRequestsState: StatementRequestsState;
  profileState: ProfileState;
}

export const createStatementRequest = createAsyncThunk<StatementRequestDTO | null, StatementRequestDTO, {state: StateSlice}>(
  "statementRequests/create", async(request: StatementRequestDTO, {getState}
) => {
  const {profile} = getState().profileState;
  const {
    filters: {onlyPersonal},
  } = getState().statementRequestsState;
  try {
    const data: StatementRequestDTO = await _createStatementRequest(request);
    notification.success({message: "Запрос успешно подан"});

    if (profile?.id && !request.isPublic && !onlyPersonal) {
      return null;
    } else {
      return data;
    }
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});