import {useKeycloak} from "@react-keycloak/web";
import {BASKET_PAGE_PATH} from "app/routing/basket/constants";
import {useAppNavigation} from "app/routing/use-navigation/use-navigation";
import {useLocation} from "react-router-dom";

export const useBasket = () => {
  const {keycloak} = useKeycloak();
  const location = useLocation();
  const navigator = useAppNavigation();

  const onClick = () => {
    navigator.toBasket();
  };

  return {
    hide: !keycloak.authenticated,
    active: location.pathname === BASKET_PAGE_PATH,
    onClick,
  };
};
