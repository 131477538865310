import {AppPrimaryButton} from "common/button/primary/Primary";
import {AppCard} from "common/card/Card";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {AppTitle} from "common/title/Title";
import {getDeclination} from "utils/lexicon/getDeclination";
import {priceFormat} from "utils/numbers/priceFormat";

import styles from "./styles.module.scss";

type TotalPriceProps = {
  total: { totalCount: number; totalAmount: number };
  onBuyClick: () => void;
};

export const TotalPrice = (props: TotalPriceProps) => {
  return (
    <AppSpacer className={styles.container}>
      <AppPrimaryButton
        className={styles.button}
        onClick={props.onBuyClick}
        disabled={props.total.totalCount <= 0}
      >
        Купить
      </AppPrimaryButton>
      <AppCard>
        <AppTitle level={1} className={styles.title}>
          Итого
        </AppTitle>
        <AppSpacer
          direction="horizontal"
          justifyContent="space-between"
          className={styles.total}
        >
          <AppText>
            {props.total.totalCount}{" "}
            {getDeclination(props.total.totalCount, [
              "товар",
              "товара",
              "товаров",
            ])}
          </AppText>
          <AppText>{priceFormat(props.total.totalAmount)} ₽</AppText>
        </AppSpacer>
      </AppCard>
    </AppSpacer>
  );
};
