import {AppContentFiltersList} from "app/components/search/content-filters-list/ContentFiltersList";
import {AppSpacer} from "common/spacer/Spacer";
import {AppLoaderPlaceholder} from "common/loader-placeholder/LoaderPlacehlder";
import {useContentByTypeService} from "./hooks/service";
import {SimilarContentPanel} from "./components/similar-content-panel/SimilarContentPanel";
import {SearchByTypePagination} from "../pagination/SearchByTypePagination";
import {ContentCardList} from "../../content-list/ContentList";
import styles from "./styles.module.scss";

export const ContentByType = () => {
  const {
    elements,
    loading,
    searchParameters,
    shouldRenderSimilarContentPanel,
    highlight,
  } = useContentByTypeService();
  const cardListContainerClassName = !shouldRenderSimilarContentPanel
    ? styles["content-card-list-container"]
    : undefined;

  return (
    <AppSpacer>
      <AppSpacer
        unit="20"
        direction="horizontal"
        justifyContent="space-between"
      >
        <AppSpacer unit="20" className={styles.container}>
          {shouldRenderSimilarContentPanel && (
            <SimilarContentPanel contentId={searchParameters.contentId!}/>
          )}
          {loading ? (
            <AppLoaderPlaceholder/>
          ) : (
            <>
              <ContentCardList
                elements={elements}
                highlight={highlight}
                containerClassName={cardListContainerClassName}
              />
              <SearchByTypePagination/>
            </>
          )}
        </AppSpacer>
        <AppContentFiltersList/>
      </AppSpacer>
    </AppSpacer>
  );
};
