import {changeBasketItem} from "app/actions/basket/basket";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useDispatch} from "react-redux";
import {UpdateBasketItemParams} from "rest/basket/types";

export const useChangeAmount = (contentId: string) => {
  const dispatch = useDispatch();
  const {basketItems} = useAppSelector((state) => state.basketState);

  const basketItem = basketItems.find((item) => item.content.id === contentId);

  const increment = () => {
    const amount = basketItem?.amount ?? 1;

    const params: UpdateBasketItemParams = {
      amount: amount + 1,
      id: basketItem?.id || "",
    };

    const action = changeBasketItem(params);

    dispatch(action);
  };

  const decrement = () => {
    const amount = basketItem?.amount ?? 1;

    const params: UpdateBasketItemParams = {
      amount: amount - 1,
      id: basketItem?.id || "",
    };

    const action = changeBasketItem(params);

    dispatch(action);
  };

  return {
    amount: basketItem?.amount,
    increment,
    decrement,
  };
};
