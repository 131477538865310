import {AppFormItem} from "common/form/item/Base";
import {AppInput} from "common/input/Input";
import {FormItemContainer} from "../../form-item-container/FormItemContainer";
import styles from "./styles.module.scss";

type FilterInputItemProps = {
  fieldName: string;
  title: string;
}

export const FilterInputItem = (props: FilterInputItemProps) => {
  return (
    <FormItemContainer title={props.title}>
      <AppFormItem name={props.fieldName} paddingless className={styles["form-item"]}>
        <AppInput borderless className={styles.input}/>
      </AppFormItem>
    </FormItemContainer>
  );
};