import {createAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "app/store/types";
import {UDCLBCOrderDTO} from "types/entities/UDCLBCOrderDTO";

export type UdcLbcRequestAppendedResult = {
  elements: UDCLBCOrderDTO[];
  total: number;
}

export const udcLbcRequestAppended = createAction<UdcLbcRequestAppendedResult>("udcLbcRequests/append");

export const appendUdcLbcRequest = (udcLbcRequest: UDCLBCOrderDTO) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {profile} = getState().profileState;
    const {
      filters: {onlyPersonal},
      elements,
      tableState: {pagination: {total = 0}}
    } = getState().udcLbcRequestsState;

    if (profile?.id && !udcLbcRequest.isPublic && !onlyPersonal) {
      return;
    }

    dispatch(udcLbcRequestAppended({
      elements: [udcLbcRequest, ...elements],
      total: total + 1,
    }));
  };
};