import {ArrowDownIcon} from "common/icons/ArrowDown";
import {AppSpacer} from "common/spacer/Spacer";
import {AppTitle} from "common/title/Title";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {getDeclination} from "utils/lexicon/getDeclination";

import styles from "./styles.module.scss";

type PanelHeaderProps = {
  isOpen: boolean;
  commentsCount: number;
};

export const PanelHeader = (props: PanelHeaderProps) => {
  const className = getClassNames(props.isOpen && styles.open);

  return (
    <AppSpacer
      direction="horizontal"
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <AppTitle level={2}>
        {props.commentsCount}{" "}
        {getDeclination(props.commentsCount, [
          "комментарий",
          "комментария",
          "комментариев",
        ])}
      </AppTitle>
      {props.commentsCount > 0 && (
        <ArrowDownIcon stroke={"#1774CB"} className={className}/>
      )}
    </AppSpacer>
  );
};
