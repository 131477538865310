import {AppSingleSelectProps, AppOptionType, AppOptionsType} from "../../types";
import {useSelectState} from "../../base/hooks/state";

export const useSingleOptionSelectState = (props: AppSingleSelectProps) => {
  const {value, onClear, onChange: _onChange} = useSelectState<string>(props, "");

  const getValue = () => {
    if (!props.options) {
      return undefined;
    }

    return !value ? undefined : value;
  };

  const onChange = (_value: string, option: AppOptionType | AppOptionsType) => {
    if (props.onChange) {
      props.onChange(_value, option);
    }

    _onChange(_value);
  };

  return {
    value: getValue(),
    onChange,
    onClear,
  };
};