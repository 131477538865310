import {AppDropdown} from "common/dropdown/Dropdown";
import {AppImage} from "common/image/Image";
import {AppTextMenu} from "common/menu/text-menu/TextMenu";
import {AppTitle} from "common/title/Title";
import {AppSpacer} from "common/spacer/Spacer";
import {useProfile} from "./hook/use-profile";
import {getDownloadUrl} from "utils/get-download-url/get-download-url";
import styles from "./styles.module.scss";

export const Profile = () => {
  const {state, methods} = useProfile();

  const getOverlay = () => {
    return (
      <AppTextMenu className={styles.menu} actions={methods.getActions()}/>
    );
  };

  const content = (
    <AppSpacer alignItems="center" className={styles["content-container"]}>
      <AppDropdown overlay={getOverlay()} trigger={["click"]}>
        <div>
          <AppImage
            className={styles.avatar}
            alt="avatar"
            src={getDownloadUrl(state.avatar)}
          />
          <AppTitle
            level={4}
            className={`${styles.title} ${
              state.authenticated ? "" : styles.anonymous
            }`}
          >
            {state.authenticated ? state?.userName : "Войти"}
          </AppTitle>
        </div>
      </AppDropdown>
    </AppSpacer>
  );

  return state.authenticated ? (
    <AppSpacer
      className={styles.container}
      direction="horizontal"
      alignItems="center"
    >
      {content}
    </AppSpacer>
  ) : (
    <AppSpacer
      onClick={methods.login}
      unit="8"
      className={`${styles.container} ${
        state.authenticated ? "" : styles["container-anonymous"]
      }`}
    >
      <AppSpacer className={styles["content-container"]}>
        <AppImage
          className={styles.avatar}
          alt="avatar"
          src={getDownloadUrl(state.avatar)}
        />
        <AppTitle
          level={4}
          className={`${styles.title} ${
            state.authenticated ? "" : styles.anonymous
          }`}
        >
          {state.authenticated ? state?.userName : "Войти"}
        </AppTitle>
      </AppSpacer>
    </AppSpacer>
  );
};
