import {useEffect, useState} from "react";
import {getDynamicHeaderLinks} from "rest/common/get-header-links";
import {DynamicHeaderLink} from "types/entities/DynamicHeaderLink";
import {DropdownAction} from "common/menu/types";

export const useHeaderLinks = () => {
  const [links, setLinks] = useState<DynamicHeaderLink[]>([]);

  useEffect(() => {
    getDynamicHeaderLinks().then(setLinks);
  }, []);

  const getActions = (): DropdownAction[] => {
    return links.slice(3).map(link => {
      return {
        title: link.description,
        action: () => window.open(link.link),
      };
    });
  };

  return {
    links,
    actions: getActions()
  };
};