import {HeaderSearchPanel} from "app/components/common/header-search-panel/HeaderSearchPanel";
import {ContentByType} from "app/components/search/search-content/content-by-type/ContentByType";
import {ContentAllTypes} from "app/components/search/search-content/content-all-types/ContentAllTypes";
import {AppLayout} from "common/layout/Layout";
import {useSearchService} from "./hooks/service";

const DOCUMENT_TITLE = "Результаты поиска";

export const SearchPage = () => {
  const {isContentByType} = useSearchService();

  return (
    <AppLayout documentTitle={DOCUMENT_TITLE}>
      <HeaderSearchPanel/>
      {isContentByType ? <ContentByType/> : <ContentAllTypes/>}
    </AppLayout>
  );
};
