export const RemoveFromBasket = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.15385H17M5.66667 5.15385V4.46154C5.66667 3.54348 6.01786 2.66303 6.64298 2.01386C7.2681 1.3647 8.11595 1 9 1C9.88406 1 10.7319 1.3647 11.357 2.01386C11.9821 2.66303 12.3333 3.54348 12.3333 4.46154V5.15385M7 9.30908V14.8503M11 9.30908V14.8503M3 5.15385H15V17.6154C15 17.9826 14.8595 18.3348 14.6095 18.5945C14.3594 18.8541 14.0203 19 13.6667 19H4.33333C3.97971 19 3.64057 18.8541 3.39052 18.5945C3.14048 18.3348 3 17.9826 3 17.6154V5.15385Z"
        stroke="#A4A4A4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
