import {SvgProps} from "types/props";

export const CloseIcon = (props: SvgProps) => {
  const {stroke = "#000", ...rest} = props;

  return (
    <svg {...rest} width="1.6rem" height="1.6rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L15 15" stroke={stroke}/>
      <path d="M15 1L1 15" stroke={stroke}/>
    </svg>
  );
};