import {notification} from "antd";
import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";

export const removeFromBasket = (basketItemId: string): Promise<string> => {
  return appApi
    .delete(`/portal/basket_item?basketItemId=${basketItemId}`)
    .then(() => {
      notification.success({message: "Товар успешно удален из корзины"});

      return Promise.resolve(basketItemId);
    })
    .catch(errorHandler("Не удалось удалить товар из корзины"));
};
