import {useState} from "react";
import {AppSpacer} from "common/spacer/Spacer";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {getSearchPageLink, getURLSearchParameters} from "utils/search/helpers";
import {ToggleButton} from "./toggle-button/ToggleButton";
import {AppContentTypeLink} from "./content-type-link/ContentTypeLink";
import {useAppSelector} from "app/store/hooks/use-selector";
import styles from "./styles.module.scss";

type AppContentTypesListProps = {
  className?: string;
}

export const AppContentTypesList = (props: AppContentTypesListProps) => {
  const {contentTypes} = useAppSelector(state => state.contentTypesState);
  const [visible, setVisible] = useState(true);
  const hiddenClassName = visible ? "" : styles["container-hidden"];
  const className = getClassNames(styles.container, hiddenClassName);
  const {searchString, searchBy} = getURLSearchParameters();

  const getLink = (contentTypeId: string) => {
    return getSearchPageLink({searchString, searchBy, contentTypeId});
  };

  return (
    <AppSpacer unit="12" className={props.className}>
      <ToggleButton visible={visible} onClick={() => setVisible(!visible)}/>
      <aside className={styles.types}>
        <AppSpacer unit="12" className={className}>
          {
            contentTypes.map(item => {
              return (
                <AppContentTypeLink key={item.id} type={item}
                                    to={getLink(item.id)}/>
              );
            })
          }
        </AppSpacer>
      </aside>
    </AppSpacer>
  );
};