import {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {useForm} from "antd/lib/form/Form";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useSearch} from "app/hooks/search";
import {getURLSearchParameters} from "utils/search/helpers";
import {FilterDto, FiltersForm} from "../types";
import {getInitialFormValues} from "../utils/get-initial-form-values";
import {getSearchParameters} from "../utils/get-search-parameters";
import {getContentTypeFilters} from "rest/search/get-available-filters";

export const useFiltersFormService = () => {
  const {contentTypes} = useAppSelector(state => state.contentTypesState);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<FilterDto[]>([]);
  const [form] = useForm<FiltersForm>();
  const location = useLocation();
  const search = useSearch();

  const contentType = useMemo(() => {
    const {contentTypeId} = getURLSearchParameters();
    return contentTypes.find(type => type.id === contentTypeId);
  }, [contentTypes]);

  useEffect(() => {
    if (contentType?.id) {
      getContentTypeFilters(contentType?.id)
        .then(setFilters)
        .finally(() => setLoading(false));
    }
  }, [contentType]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(getInitialFormValues(filters));
  }, [location, filters]);

  const onSubmit = () => {
    form.validateFields()
      .then(values => {
        const nextSearchParameters = getSearchParameters(values, filters);
        search(nextSearchParameters);
      });
  };

  return {
    state: {
      filters,
      form,
      loading,
      contentType,
    },
    methods: {
      onSubmit,
    }
  };
};