import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getNewAndPopularContents} from "app/actions/new-popular/new-popular";
import {NewAndPopularResponse, NewAndPopularState} from "./types";

const initialState: NewAndPopularState = {
  newContents: [],
  popularContents: [],
  loading: true,
};

const newAndPopularContentSlice = createSlice({
  name: "newAndPopularContent",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    [getNewAndPopularContents.pending.type]: (state: NewAndPopularState) => {
      state.loading = true;
    },
    [getNewAndPopularContents.rejected.type]: (state: NewAndPopularState) => {
      state.loading = false;
    },
    [getNewAndPopularContents.fulfilled.type]: (state: NewAndPopularState, action: PayloadAction<NewAndPopularResponse>) => {
      state.newContents = action.payload.newContents;
      state.popularContents = action.payload.popularContents;
      state.loading = false;
    },
  }
});

export const {
  reducer: newAndPopularContentReducer,
  actions: newAndPopularContentActions,
} = newAndPopularContentSlice;

