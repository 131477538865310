import {
  ContentMinCardList,
  ContentMinCardLists,
} from "app/components/search/content-min-list/ContentMinList";
import {AppSpacer} from "common/spacer/Spacer";
import {AppEmpty} from "common/empty/Empty";
import {ResourceElement, SearchState} from "app/reducers/search/types";
import {groupByType} from "utils/group-by-type/group-by-type";
import styles from "./styles.module.scss";

type HomeContentProps = {
  newContent: ResourceElement[];
  popularContent: ResourceElement[];
  searchState: SearchState;
};

export const HomeContent = (props: HomeContentProps) => {
  const {elements, contentTypeToCounts} = props.searchState;
  const groupedElements = groupByType(elements);
  return (
    <AppSpacer unit="50" className={styles.container}>
      {!(props.newContent.length || props.popularContent.length) && (
        <AppEmpty/>
      )}
      {!!props.newContent.length && (
        <ContentMinCardList
          title="Новинки"
          data={props.newContent}
          link={""}
          hideHeaderLink={true}
        />
      )}
      {!!props.popularContent.length && (
        <ContentMinCardList
          title="Популярное"
          data={props.popularContent}
          link={""}
          hideHeaderLink={true}
        />
      )}
      {!!props.searchState.elements.length && (
        <ContentMinCardLists
          elements={groupedElements}
          elementsCount={contentTypeToCounts}
        />
      )}
    </AppSpacer>
  );
};
