import React from "react";
import {Input} from "antd";
import {InputProps} from "antd/lib/input";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {WithClassName} from "types/props";
import {AppInputPrefix} from "./components/prefix/AppInputPrefix";
import {AppInputPostfix} from "./components/postfix/AppInputPostfix";
import styles from "./styles/styles.module.scss";

export type AppInputProps = Omit<InputProps, "bordered"> & WithClassName & {
  borderless?: boolean;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  postfixDisabled?: boolean;
};

export const AppInput = (props: AppInputProps) => {
  const {className, prefix, postfix, postfixDisabled, borderless, autoComplete = "off", ...rest} = props;
  const classNames = computeClassNames(className, borderless, rest.disabled);

  return (
    <div className={classNames.container}>
      <AppInputPrefix prefix={prefix}/>
      <Input {...rest} autoComplete={autoComplete} className={classNames.input}/>
      <AppInputPostfix postfix={postfix} disabled={postfixDisabled}/>
    </div>
  );
};

const computeClassNames = (containerClass?: string, borderless?: boolean, disabled?: boolean) => {
  const borderlessClass = borderless ? "-borderless" : "";

  const containerMainClass = disabled ? styles[`container${borderlessClass}--disabled`] : styles[`container${borderlessClass}`];
  const inputMainClass = disabled ? styles[`input${borderlessClass}--disabled`] : styles[`input${borderlessClass}`];

  return {
    container: getClassNames(`${containerMainClass} app-input${borderlessClass}`, containerClass),
    input: inputMainClass,
  };
};