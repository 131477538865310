import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";
import {BypassSheetServerResponse} from "./types";

export const createBypassSheet = (userId: string, ticketNumber: string): Promise<BypassSheetServerResponse> => {
  return appApi
    .post("v2/services/educationalportal_BypassSheetService/createBypassSheet", {
      bypassSheetDTO: {
        user: {
          _entityName: "educationalportal_ExtUser",
          id: userId,
        },
        readerId: ticketNumber
      }
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось создать запрос"));
};