import {SearchIcon} from "common/icons/Search";
import {CloseIcon} from "common/icons/Close";
import {AppInput, AppInputProps} from "../Input";
import styles from "./styles.module.scss";

type AppSearchInputProps = Omit<AppInputProps, "prefix" | "postfix" | "onPressEnter"> & {
  onSearch(): void;
  onClear(): void;
};

export const AppSearchInput = (props: AppSearchInputProps) => {
  const {onSearch, onClear, placeholder = "Искать", ...rest} = props;
  const postfixDisabled = props.disabled || props.postfixDisabled;

  const onClose = () => {
    if (!postfixDisabled) {
      onClear();
    }
  };

  const postfix = props.value ? (
    <CloseIcon className={styles.close} onClick={onClose}/>
  ) : null;

  return (
    <AppInput {...rest} onPressEnter={() => onSearch()}
              prefix={<SearchIcon/>} postfix={postfix}
              placeholder={placeholder}
              postfixDisabled={postfixDisabled}/>
  );
};