import React from "react";
import {AppTextHighlighter} from "common/text-highlighter/TextHighlighter";
import {HighlightPattern, RenderHighlightedNodeOptions} from "./types";

export const renderHighlightedNode = (options: RenderHighlightedNodeOptions) => {
  const {partsToHighlight, currentPart, node, pattern} = options;

  if (!node || !pattern || !(partsToHighlight?.some(part => part === currentPart))) {
    return node;
  }

  return highlightNode(node, pattern);
};

export const highlightNode = (node: React.ReactNode, pattern: HighlightPattern): React.ReactNode => {
  return React.Children.map(node, child => {
    if (typeof child === "string") {
      return (
        <AppTextHighlighter text={child} highlight={pattern}/>
      );
    }

    if (!React.isValidElement(child) || !child.props.children) {
      return child;
    }

    return React.cloneElement(child, {
      children: highlightNode(child.props.children, pattern),
    });
  });
};