import {appApi} from "api-config";
import {FilterDto} from "app/components/search/content-filters-list/filters-form/types";
import {errorHandler} from "utils/error-handler";

export const getContentTypeFilters = (contentTypeId: string): Promise<FilterDto[]> => {
  return appApi
    .post("v2/services/educationalportal_RestSearchService/getContentTypeFilter", {
      contentTypeId,
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось загрузить доступные фильтры"));
};