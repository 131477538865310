import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {VirtualReferenceState} from "app/reducers/virtual-reference/types";
import {virtualReferenceActions} from "app/reducers/virtual-reference";
import {ProfileState} from "app/reducers/profile/types";
import {getOffsetLimit} from "common/table/utils/get-offset-limit";
import {getVirtualReferenceList, VirtualReferenceListParams} from "rest/virtual-reference/get-all";
import {InquiryOrderDTO} from "types/entities/InquiryOrderDTO";

type StateSlice = {
  virtualReferenceState: VirtualReferenceState;
  profileState: ProfileState;
}

export const fetchVirtualReferenceList = createAsyncThunk<InquiryOrderDTO[], void, {state: StateSlice}>(
  "virtualReference/fetchAll",
  async (_, {getState, dispatch}) => {
    try {
      const {filters: {onlyPersonal}, tableState: {pagination}} = getState().virtualReferenceState;
      const {profile} = getState().profileState;
      const params: VirtualReferenceListParams = {
        id: profile?.id || null,
        onlyPersonal,
        ...getOffsetLimit(pagination),
      };
      return await getVirtualReferenceList(params);
    } catch (e: unknown) {
      if (!axios.isCancel(e)) {
        dispatch(virtualReferenceActions.rejectedByError());
      }
      throw new Error(e as string);
    }
  }
);