import {createAsyncThunk} from "@reduxjs/toolkit";
import {createBypassSheet} from "rest/bypass-sheets/create-bypass-sheets";
import {getBypassSheetsForUser} from "rest/bypass-sheets/get-bypass-sheets";
import {BypassSheetServerResponse} from "rest/bypass-sheets/types";

type RequestBypassSheetData = {
  userId: string;
  ticketNumber: string;
}

export const requestBypassSheet = createAsyncThunk("bypassSheets/create", async(data: RequestBypassSheetData) => {
  try {
    const response: BypassSheetServerResponse = await createBypassSheet(data.userId, data.ticketNumber);
    return response;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const getAllBypassSheetsForUser = createAsyncThunk("bypassSheets/getAll", async(userId: string) => {
  try {
    const response: BypassSheetServerResponse = await getBypassSheetsForUser(userId);
    return response;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});