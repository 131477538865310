import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {fetchAccessToken} from "rest/auth/token";
import {FetchAccessTokenResponse} from "rest/auth/types";

export const ACCESS_TOKEN_NAME = "sgmu_access_token";
export const CUBA_APP_URL = process.env.REACT_APP_API_URL || "/edu/rest/";

export const appApi = axios.create({
  baseURL: CUBA_APP_URL,
});

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_NAME);
};

appApi.interceptors.request.use((request: AxiosRequestConfig) => {
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }
  return request;
});

export const refreshAccessToken = async () => {
  const response: AxiosResponse<FetchAccessTokenResponse> = await fetchAccessToken();
  localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access_token);
  return response;
};

const refreshAuthLogic = async (failedRequest: any) => {
  const response = await refreshAccessToken();
  failedRequest.response.config.headers["Authorization"] = "Bearer " + response.data.access_token;
  return Promise.resolve();
};

createAuthRefreshInterceptor(appApi, refreshAuthLogic);