import axios, {CancelTokenSource} from "axios";
import {appApi} from "api-config";
import {UDCLBCOrderDTO} from "types/entities/UDCLBCOrderDTO";
import {errorHandler} from "utils/error-handler";

export type UdcLbcRequestsListParams = {
  userId: string | null;
  onlyPersonal: boolean;
  limit: number;
  offset: number;
}

const _getUdcLbcRequestsList = () => {
  let source: CancelTokenSource | undefined;

  return (params: UdcLbcRequestsListParams): Promise<UDCLBCOrderDTO[]> => {
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();

    return appApi
      .post(
        "v2/services/educationalportal_RestUDCLBCOrderService/getOrderList",
        {userId: params.userId, onlyPersonal: params.onlyPersonal},
        {cancelToken: source.token}
      )
      .then(res => res.data)
      .catch(errorHandler("Не удалось загрузить реестр запросов УДК, ББК"));
  };
};

export const getUdcLbcRequestsList = _getUdcLbcRequestsList();