export enum ESearchBy {
  ALL = "ALL",
  DESCRIPTION = "DESCRIPTION",
  ATTACHED_DOCUMENTS = "ATTACHED_DOCUMENTS",
}

export enum EPrice {
  ALL = "ALL",
  FREE = "FREE",
}

export enum EHighlightPart {
  NAME = "NAME",
  PUBLISH_DATE = "PUBLISH_DATE",
  AUTHOR = "AUTHOR",
  DESCRIPTION = "DESCRIPTION",
  SOURCE_NAME = "SOURCE_NAME",
}

export enum ESpeciesComposition {
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum EOrderState {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  AGREEMENT = "AGREEMENT",
  COMPLETE = "COMPLETE",
}

export enum EUDCLBCOrderType {
  UDC = "UDC",
  LBC = "LBC",
  UDC_LBC = "UDC_LBC",
}

export enum EBypassSheetResultType {
  NO_DEBT = "NO_DEBT",
  YES_DEBT = "YES_DEBT",
}

export enum EMaterialType {
  BOOK = "BOOK",
  PROGRAM = "PROGRAM",
  LECTURE = "LECTURE",
  ART = "ART",
  GUIDE1 = "GUIDE1",
  TUTOR = "TUTOR",
  GUIDE3 = "GUIDE3",
}

export enum EFilterType {
  TEXT = "text",
  KEYWORD = "keyword",
  LONG = "long",
  DOUBLE = "double",
  DATE = "date",
  BOOLEAN = "boolean",
}