import {appApi} from "api-config";
import {CommentsResponse} from "rest/comments/types";
import {errorHandler} from "utils/error-handler";

export const getCommentsById = (
  contentId: string
): Promise<CommentsResponse> => {
  return appApi
    .get(
      `/portal/reviews_by_portal_content?portalContent=${contentId}&limit=10&offset=0`
    )
    .then((res) => res.data)
    .catch(errorHandler("Не удалось загрузить комментарии"));
};
