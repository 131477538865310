import {useDocumentTitle} from "app/hooks/document-title/document-title";
import {useScrollToTop} from "app/hooks/scroll-to-top";
import {WithChildren} from "types/props";
import styles from "./styles.module.scss";

type AppLayoutProps = WithChildren & {
  documentTitle?: string | null;
}

export const AppLayout = (props: AppLayoutProps) => {
  useDocumentTitle(props.documentTitle);
  useScrollToTop();

  return (
    <main className={styles.page}>
      {props.children}
    </main>
  );
};