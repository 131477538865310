import {Input} from "antd";
import {TextAreaProps} from "antd/lib/input/TextArea";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

type AppTextAreaProps = TextAreaProps & {
  containerClassName?: string;
}

export const AppTextArea = (props: AppTextAreaProps) => {
  const {containerClassName, ...rest} = props;
  const className = getClassNames(styles.textarea, containerClassName);

  return (
    <div className={className}>
      <Input.TextArea {...rest}/>
    </div>
  );
};