import {withLoading} from "app/hocs/with-loading/with-loading";
import {AppSpacer} from "common/spacer/Spacer";
import {ContentMinCardLists as _ContentMinCardLists} from "../../content-min-list/ContentMinList";
import {AppContentTypesList} from "../../content-types-list/ContentTypesList";
import {useContentAllTypesService} from "./hooks/service";

const ContentMinCardLists = withLoading(_ContentMinCardLists);

export const ContentAllTypes = () => {
  const {
    loading,
    groupedElements,
    contentTypeToCounts,
    highlight,
  } = useContentAllTypesService();

  return (
    <AppSpacer unit="20" direction="horizontal" justifyContent="space-between">
      <ContentMinCardLists
        loading={loading}
        elements={groupedElements}
        elementsCount={contentTypeToCounts}
        highlight={highlight}
      />
      <AppContentTypesList/>
    </AppSpacer>
  );
};
