import {appApi} from "api-config";
import {NewAndPopularResponseV2} from "app/reducers/new-and-popular/types";
import {errorHandler} from "utils/error-handler";

export const searchNewAndPopularContent = (
  userId?: string
): Promise<NewAndPopularResponseV2> => {
  return appApi
    .get("portal/new_and_popular_contents", {
      params: {
        userId,
      },
    })
    .then((res) => res.data)
    .catch(errorHandler("При обращении к серверу произошла ошибка."));
};
