import {Dropdown, DropDownProps} from "antd";
import {WithChildren} from "types/props";

type AppDropdownProps = DropDownProps & WithChildren;

export const AppDropdown = (props: AppDropdownProps) => {
  const {trigger = ["click"], ...rest} = props;

  return (
    <Dropdown {...rest} className={props.className} trigger={trigger}>
      {props.children}
    </Dropdown>
  );
};