import {AppCard} from "common/card/Card";
import {CloseIcon} from "common/icons/Close";
import {AppText} from "common/text/Text";
import {useEffect, useState} from "react";
import styles from "./styles.module.scss";

export const CookiesInfo = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookiesAccepted")) {
      setVisible(true);
    }
  }, []);

  const close = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setVisible(false);
  };

  return visible ? (
    <AppCard className={styles.card}>
      <AppText>
        Мы используем файлы cookie. Они помогают улучшить ваше взаимодействие с сайтом.
      </AppText>
      <CloseIcon onClick={close} className={styles.close}/>
    </AppCard>
  ) : null;
};