import {Select} from "antd";
import {AppSelectOption} from "../../types";

export const getDefaultOption = (option: AppSelectOption) => {
  const key = `${option.value}-${option.text}`;

  return (
    <Select.Option value={option.value} key={key}
                   title={option.text}>
      {option.text}
    </Select.Option>
  );
};