import {Typography} from "antd";
import {ParagraphProps} from "antd/lib/typography/Paragraph";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles/styles.module.scss";
import "./styles/styles.scss";

export type AppTextType = "primary" | "secondary" | "success" | "warning" | "danger";

type AppTextBaseProps = {
  weight?: "thin" | "light" | "regular" | "bold" | "black";
  type?: AppTextType;
  size?: 12 | 14 | 16 | 18 | 20 | 30;
}

export type AppTextProps =
  Omit<ParagraphProps, keyof AppTextBaseProps> &
  AppTextBaseProps;

export const AppText = (props: AppTextProps) => {
  const {weight = "regular", type = "primary", size = 14, className, ...rest} = props;
  const classNames = getClassNames(
    styles.text,
    styles[`text-weight--${weight}`],
    styles[`text-type--${type}`],
    styles[`text-size--${size}`],
    className,
  );

  return (
    <Typography.Paragraph className={classNames} {...rest}>
      {props.children}
    </Typography.Paragraph>
  );
};