import {CubaUser} from "types/entities/CubaUser";
import {ResourceElement} from "app/reducers/search/types";
import {AppFormDescription} from "common/descriptions/form-description/FormDescription";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import styles from "./styles.module.scss";

export type ReserveModalProps = {
  element: ResourceElement;
  profile: CubaUser;
}

export const ReserveContentInfo = (props: ReserveModalProps) => {
  return (
    <>
      <AppSpacer direction="horizontal">
        {
          props.profile?.lastName && (
            <AppFormDescription description="Фамилия"
                                containerClassName={styles["profile-item"]}>
              <AppText size={16}>{props.profile.lastName}</AppText>
            </AppFormDescription>
          )
        }
        {
          props.profile?.firstName && (
            <AppFormDescription description="Имя"
                                containerClassName={styles["profile-item"]}>
              <AppText size={16}>{props.profile?.firstName}</AppText>
            </AppFormDescription>
          )
        }
        {
          props.profile?.middleName && (
            <AppFormDescription description="Отчество"
                                containerClassName={styles["profile-item"]}>
              <AppText size={16}>{props.profile?.middleName}</AppText>
            </AppFormDescription>
          )
        }
      </AppSpacer>
      {
        props.profile?.userTypeName && (
          <AppFormDescription description="Видовой состав"
                              containerClassName={styles["profile-item"]}>
            <AppText size={16}>{props.profile.userTypeName}</AppText>
          </AppFormDescription>
        )
      }
      <div className={styles.divider}/>
      {
        props.element.name && (
          <AppFormDescription description="Название">
            <AppText size={16}>{props.element.name}</AppText>
          </AppFormDescription>
        )
      }
      {
        props.element.authors && (
          <AppFormDescription description="Автор">
            <AppText size={16}>{props.element.authors}</AppText>
          </AppFormDescription>
        )
      }
      <div className={styles.divider}/>
    </>
  );
};