import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";
import {StatementRequestDTO} from "types/entities/StatementRequestDTO";

export const _createStatementRequest = (order: StatementRequestDTO): Promise<StatementRequestDTO> => {
  return appApi
    .post("v2/services/educationalportal_RestExcerptOrderService/createOrder", {
      order,
    })
    .then(res => res.data)
    .catch(errorHandler("Не удалось подать запрос"));
};