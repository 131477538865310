import React from "react";
import {ResourceElement} from "app/reducers/search/types";
import {AppCard} from "common/card/Card";
import {AppImage} from "common/image/Image";
import {AppSpacer} from "common/spacer/Spacer";
import {AppInfoText} from "common/text/InfoText";
import {AppText} from "common/text/Text";
import {AppTitle} from "common/title/Title";
import {getDownloadUrl} from "utils/get-download-url/get-download-url";
import {getFullYear} from "utils/date/get-full-year/get-full-year";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {HighlightProps} from "types/props";
import {EHighlightPart} from "types/enums";
import {renderHighlightedNode} from "utils/highlight/render-highlighted-node/render-highlighted-node";
import styles from "./styles.module.scss";
import previewStyles from "../styles.module.scss";

type ContentCardMinProps = HighlightProps & {
  element: ResourceElement;
  className?: string;
}

export const ContentCardMin = (props: ContentCardMinProps) => {
  const {image, name = "", publishDate, authors, contentType} = props.element;
  const classNames = getClassNames(styles.card, props.className);
  const date = `${getFullYear(publishDate) ?? ""}`;

  const renderHighlighted = (currentPart: EHighlightPart, node: React.ReactNode) => {
    return renderHighlightedNode({
      partsToHighlight: props.highlight?.highlightParts,
      currentPart,
      node,
      pattern: props.highlight?.pattern,
    });
  };

  return (
    <AppCard className={classNames}>
      <AppSpacer direction="horizontal" unit="30">
        <AppImage previewClassName={previewStyles[`preview-${contentType?.materialType}`]}
                  className={styles.image} src={getDownloadUrl(image)} alt={name}/>
        <AppSpacer justifyContent="space-between" className={styles["info-container"]}>
          <AppSpacer unit="10" direction="horizontal">
            <AppTitle level={4} className={styles.title}>
              {renderHighlighted(EHighlightPart.NAME, name)}{name && date ? "," : ""}
            </AppTitle>
            <AppText className={styles.date}>
              {renderHighlighted(EHighlightPart.PUBLISH_DATE, date)}{date ? "г." : ""}
            </AppText>
          </AppSpacer>
          {
            authors && (
              <AppSpacer unit="0">
                <AppInfoText>Автор:</AppInfoText>
                <AppText className={styles.author}>
                  {renderHighlighted(EHighlightPart.AUTHOR, authors)}
                </AppText>
              </AppSpacer>
            )
          }
        </AppSpacer>
      </AppSpacer>
    </AppCard>
  );
};