import React from "react";
import {DatePicker} from "antd";
import {DatePickerProps} from "antd/lib/date-picker";
import {Moment} from "moment";
import {USER_DATE_FORMAT} from "utils/date/date";
import {CalendarIcon} from "../icons/Calendar";
import {getDisabledDateResolver} from "./utils/get-disabled-date";
import "./styles/index.scss";

export type DatePickerComponent = React.Component<DatePickerProps>;
export type AppDatePickerProps = DatePickerProps & {
  minDate?: Moment | null,
  maxDate?: Moment | null,
};

export const AppDatePicker = React.forwardRef<DatePickerComponent, AppDatePickerProps>(
  (props: AppDatePickerProps, ref) => {
    const disabledDate = getDisabledDateResolver(props.minDate, props.maxDate);

    return (
      <DatePicker format={USER_DATE_FORMAT}
                  suffixIcon={<CalendarIcon/>}
                  disabledDate={disabledDate}
                  {...props} ref={ref}/>
    );
  }
);