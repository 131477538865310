// import {LIBRARY_PAGE_LINK} from "app/routing/library/constants";
import {AppDropdown} from "common/dropdown/Dropdown";
import {AppAnchorLinkCard} from "common/link-card/AnchorLinkCard";
// import {AppLinkCard} from "common/link-card/LinkCard";
import {AppTextMenu} from "common/menu/text-menu/TextMenu";
import {AppSpacer} from "common/spacer/Spacer";
import {useHeaderLinks} from "./hook";
import linkStyles from "common/link-card/styles.module.scss";
import styles from "./styles.module.scss";
import {SamgmuLogoIcon} from "common/icons/SamgmuLogo";

export const HeaderLinks = () => {
  const {links, actions} = useHeaderLinks();

  const getOverlay = () => {
    return <AppTextMenu className={styles.menu} actions={actions}/>;
  };

  return (
    <AppSpacer unit="0" direction="horizontal" className={styles.container}>
      {
        links.map((item, index) => {
          if (index > 2) {
            return;
          }
          return (
            <AppAnchorLinkCard title={item.description} href={item.link} key={index}/>
          );
        })
      }
      {
        links.length > 3 && (
          <div style={{position: "relative"}}>
            <AppDropdown overlay={getOverlay()} className={styles.dropdown}>
              <div className={linkStyles.card}>
                ВСЕ РЕСУРСЫ
              </div>
            </AppDropdown>
            <SamgmuLogoIcon className={`${linkStyles.logo} ${styles.logo}`}/>
          </div>
        )
      }
      {/** Commented at 2023.04.06
      <AppLinkCard className={styles.library} title="БИБЛИОТЕКА" to={LIBRARY_PAGE_LINK}/>
      */}
    </AppSpacer>
  );
};