import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {AppLink} from "common/link/Link";
import {AppTooltip} from "common/tooltip/Tooltip";
import {useSimilarContentPanelService} from "./hooks/service";
import styles from "./styles.module.scss";

type SimilarContentPanelProps = {
  contentId: string;
}

export const SimilarContentPanel = ({contentId} : SimilarContentPanelProps) => {
  const {content, urlToShowContent, urlToShowAll} = useSimilarContentPanelService(contentId);

  return (
    <AppSpacer direction="horizontal" unit="8" className={styles.container}>
      {
        content && (
          <>
            <AppText size={16}>Показаны результаты, похожие на</AppText>
            <AppLink to={urlToShowContent} className={`${styles.link} ${styles["link-show-content"]}`}>
              <AppTooltip placement="topLeft" title={content.name}>
                {content.name}
              </AppTooltip>
            </AppLink>
            <AppLink to={urlToShowAll} className={styles.link}>
              Искать по всем
            </AppLink>
          </>
        )
      }
    </AppSpacer>
  );
};