import {appApi} from "api-config";

export const updatePaymentStatus = (
  success: boolean,
  userId: string
): Promise<void> => {
  if (success) {
    return appApi.post("/portal/success_payment", undefined, {
      params: {
        id: userId,
      },
    });
  }
  return appApi.post("/portal/error_payment", undefined, {
    params: {
      id: userId,
    },
  });
};
