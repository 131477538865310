import {notification} from "antd";
import {CUBA_APP_URL} from "api-config";
import axios, {AxiosResponse} from "axios";
import {FetchAccessTokenResponse} from "./types";

export const fetchAccessToken = (): Promise<AxiosResponse<FetchAccessTokenResponse>> => {
  return axios.post(`${CUBA_APP_URL}v2/oauth/token?grant_type=password&username=${process.env.REACT_APP_USERNAME}&password=${encodeURIComponent(process.env.REACT_APP_PASSWORD!)}`, {}, {
    headers: {
      authorization: `Basic ${process.env.REACT_APP_CLIENT_ID}`,
    }
  }).catch((err) => {
    notification.error({message: "Ошибка обращения к серверу"});
    throw new Error(err);
  });
};