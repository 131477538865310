import {Moment} from "moment";
import {EFilterType} from "types/enums";
import {SEARCH_PARAMETER_NAMES} from "types/search-params";
import {CUBA_DATE_FORMAT, USER_YEAR_FORMAT} from "utils/date/date";
import {FilterDto, FiltersForm} from "../types";

export const getSearchParameters = (searchParametersFromFilterValues: FiltersForm, availableFilters: FilterDto[]) => {
  const {searchParams} = new URL(window.location.href);
  const currentSearchParameters = SEARCH_PARAMETER_NAMES.reduce((acc, name) => {
    return {
      ...acc,
      [name]: searchParams.get(name) ? searchParams.get(name) : null
    };
  }, {});

  const nextSearchParameters = {
    ...currentSearchParameters,
    ...Object.keys(searchParametersFromFilterValues).reduce((acc, key) => {
      if (!searchParametersFromFilterValues[key]) {
        return {
          ...acc,
          [key]: null,
        };
      }
      const filter = availableFilters.find(item => item.id === key);

      if (filter && filter.type === EFilterType.DATE) {
        return {
          ...acc,
          [key]: (searchParametersFromFilterValues[key] as Moment).format(CUBA_DATE_FORMAT)
        };
      }
      if (filter && filter.fullPathToProperty === "publishDate") {
        return {
          ...acc,
          [key]: (searchParametersFromFilterValues[key] as Moment).format(USER_YEAR_FORMAT)
        };
      }
      return {
        ...acc,
        [key]: searchParametersFromFilterValues[key]
      };
    }, {})
  };

  return nextSearchParameters;
};