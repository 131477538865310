import {useSearch} from "app/hooks/search";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {DEFAULT_LIMIT} from "rest/search/search-content";
import {getURLSearchParameters} from "utils/search/helpers";

export const useSearchByTypePagination = () => {
  const {contentTypeToCounts} = useAppSelector(state => state.searchContentState);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currentSearchParameters = getURLSearchParameters();
  const search = useSearch();
  const location = useLocation();

  useEffect(() => {
    const limit = getLimit();
    const current: number = getCurrentPage(limit);
    setPageSize(limit);
    setCurrentPage(Number(current));
  }, [location]);

  const getLimit = () => {
    return currentSearchParameters.limit
      ? Math.min(Number(currentSearchParameters.limit) || DEFAULT_LIMIT, 100)
      : DEFAULT_LIMIT;
  };

  const getCurrentPage = (limit: number) => {
    return (Number(currentSearchParameters.offset) || 0) / limit + 1;
  };

  const onChange = (page: number, limit?: number) => {
    const nextSearchParameters = {
      ...currentSearchParameters,
      offset: String((page - 1) * (limit || DEFAULT_LIMIT)),
      limit: limit?.toString() || DEFAULT_LIMIT.toString(),
    };

    search(nextSearchParameters);
  };

  const getTotal = () => {
    return contentTypeToCounts && contentTypeToCounts[0] && contentTypeToCounts[0].count || 0;
  };

  return {
    state: {
      shouldRender: !!contentTypeToCounts?.length,
      currentPage,
      pageSize,
      total: getTotal(),
    },
    methods: {
      onChange,
    }
  };
};