import {LinkType} from "app/reducers/search/types";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useState} from "react";
import {setContentWasFound} from "rest/search/log-search-session-id";

export const useLinkButton = (
  link: string,
  elementId: string,
  linkType?: LinkType
) => {
  const [logsSending, setLogsSending] = useState(false);
  const searchLogId = useAppSelector(
    (state) => state.searchContentState.searchLogId
  );
  const {purchaseItems} = useAppSelector((state) => state.purchasesState);

  const toSource = async () => {
    if (searchLogId) {
      setLogsSending(true);
      try {
        await setContentWasFound(searchLogId, elementId);
      } finally {
        window.open(link!, "__blank");
        setLogsSending(false);
      }
      return;
    }
    window.open(link!, "__blank");
  };

  const userBoughtProduct =
    purchaseItems.findIndex((item) => item.content.id === elementId) !== -1;

  const getButtonText = () => {
    if (linkType == LinkType.marketPlace) {
      return userBoughtProduct ? "Перейти к ознакомлению" : "Перейти на портал";
    }
    if (linkType == LinkType.services) {
      return userBoughtProduct ? "Перейти на портал" : undefined;
    }
    return "Перейти на портал";
  };

  return {
    onClick: toSource,
    loading: logsSending,
    buttonText: getButtonText(),
  };
};
