import {SvgProps} from "types/props";

export const ArrowDownIcon = (props: SvgProps) => {
  const {stroke = "#000", ...rest} = props;

  return (
    <svg {...rest} width="1.8rem" height="1rem" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L9 9L17 1" stroke={stroke}/>
    </svg>
  );
};