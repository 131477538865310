import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";

type PurchaseParams = {
  amount: number;
  userId: string;
  basketItemIds: string[];
};

export const makePurchase = (
  purchaseParams: PurchaseParams
): Promise<string> => {
  return appApi
    .post("/portal/payment_url", purchaseParams)
    .then((res) => res.data)
    .catch(errorHandler("При попытке оплаты поизошла ошибка"));
};
