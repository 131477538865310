import {FileDescriptor} from "types/common";
import {ContentType} from "../content-types/types";

export type ContentSource = {
  id: string;
  name: string;
};

export type ContentData = {
  entityType: string;
  id: string;
  localeName: string;
  localeNames: string;
  name: string;
};

export enum LinkType {
  marketPlace = 1,
  services = 2,
}

export type ContentCategory = {
  id: string;
  name: string;
  pluralName: string;
  editable: boolean;
  number: number;
  linkType: LinkType;
};

export type ResourceElement = {
  authors?: string;
  availableForReserve?: boolean;
  availableReserveStarts?: string;
  availableReserveEnds?: string;
  canBeAvailable?: string;
  contentData?: ContentData;
  contentType?: ContentType;
  description?: string;
  forReserve?: number;
  id: string;
  averageRating?: number;
  image?: FileDescriptor;
  isbn?: string;
  lbc?: string;
  link?: string;
  name?: string;
  price?: string;
  publishDate?: string;
  source?: ContentSource;
  tags?: string;
  udc?: string;
  videoPreviewUrl?: string;
  videoUrl?: string;
  type?: string;
  multiple?: boolean;
  category?: ContentCategory;
};

export type ResourceElementV2 = {
  description: string;
  extension: string;
  id: string;
  image: string;
  name: string;
  params: { code: string; value: string }[];
  source: string;
  type: string;
  price?: string;
  promoVideoUrl?: string;
  multiple?: boolean;
  externalLink?: string;
  category?: ContentCategory;
};

export type CountElementsByType = {
  count: number;
  contentType: string;
};

export type SearchState = {
  loading: boolean;
  elements: ResourceElement[];
  contentTypeToCounts?: CountElementsByType[];
  searchLogId: string;
};

export type SearchResponse = {
  elements: ResourceElement[];
  contentTypeToCounts?: CountElementsByType[];
  searchLogId: string;
};

export type SearchResponseV2 = {
  elements: ResourceElementV2[];
  contentTypeToCounts?: CountElementsByType[];
};
