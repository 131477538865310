import {AppSingleSelectProps} from "../types";
import {useSingleOptionSelectState} from "./hooks/state";
import {textResolver} from "../base/resolvers/text";
import {getDefaultOption} from "../base/utils/default-option";
import {renderOptions} from "../base/utils/render-option";
import {AppBaseSelect} from "../base/BaseSelect";

export const AppSelect = (props: AppSingleSelectProps) => {
  const {resolver = textResolver, getOption = getDefaultOption, options, ...rest} = props;
  const {value, onChange, onClear} = useSingleOptionSelectState(props);

  return (
    <AppBaseSelect<string> showSearch allowClear {...rest}
                           value={value} onChange={onChange} onClear={onClear}
                           filterOption={resolver}>
      {renderOptions(options, getOption)}
    </AppBaseSelect>
  );
};
