import {createComment, getComments} from "app/actions/comments/comments";
import {ResourcePageParamsKeys} from "app/routing/resource/types";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {CommentDTO} from "rest/comments/add-comment";
import {v4 as UUID} from "uuid";

export const useComments = (price?: string | null) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [rating, setRating] = useState<number>(5);

  const dispatch = useAppDispatch();

  const {comments, loading, error} = useAppSelector(
    (state) => state.commentsState
  );
  const {purchaseItems} = useAppSelector((state) => state.purchasesState);
  const {id} = useParams<ResourcePageParamsKeys>();
  const {profileState} = useAppSelector((state) => state);

  useEffect(() => {
    if (id) {
      const action = getComments({contentId: id});
      dispatch(action);
    }
  }, [id]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setValue("");
    setRating(5);
  };

  const addComment = () => {
    const payload: CommentDTO = {
      id: UUID(),
      contentId: id!,
      comment: value,
      rating: rating,
      visible: true,
      userId: profileState.profile?.id ?? undefined,
    };
    const action = createComment(payload);
    dispatch(action);
    closeModal();
  };

  const userBoughtProduct =
    purchaseItems.findIndex((item) => item.content.id === id) !== -1;
  const hideAddComment =
    (profileState.profile === null && Boolean(price)) ||
    (!userBoughtProduct && !!price);

  return {
    state: {
      modalIsOpen,
      formValue: value,
      comments,
      loading,
      error,
      hideAddComment,
    },
    methods: {
      openModal,
      closeModal,
      setFormValue: setValue,
      setRating,
      addComment,
    },
  };
};
